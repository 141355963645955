import tw, { styled } from 'twin.macro';
import { Form } from 'formik';

export const ForgotPasswordEmailFormContainer = styled.div`
  ${tw`
  h-full
  flex
  flex-col
  items-center
  bg-transparent
  p-10
  font-boing
  justify-center
  z-10
  `}

  div {
    ${tw`pt-2 pb-1`}
  }

  z-index: 100000;
  @media not all and (min-resolution: 0.001dpcm) {
    div:first-child {
      ${tw`ml-0 gap-0 block relative mb-0`}
    }
  }
`;

export const StyledForgotPasswordEmailForm = styled(Form)`
  ${tw`flex flex-col pb-7`}
  div {
    ${tw`pb-1 w-64`}
  }
`;

export const ForgotPasswordEmailFormButtonContainer = styled.div`
  a {
    ${tw`mb-4`}
  }
`;

/* stylelint-disable value-no-vendor-prefix */

import tw, { styled } from 'twin.macro';

interface SideNavModuleContainerProps {
  show?: boolean;
}

export const SideNavModuleContainer =
  styled.div <
  SideNavModuleContainerProps >
  `
  :before {
    content: '';
    ${tw`
      absolute
      rounded-b-lg
      top-0
      left-0
      w-full
      z-10
      rounded-lg
      delay-700
      duration-700
      ease-in-out
      transition-opacity
      pointer-events-none
      h-13
    `}
    background: linear-gradient(#f5f5f5 70%, rgba(255, 255, 255, 0.001));
  }

  ${tw`
    flex
    fixed
    bg-gray
    rounded-lg
    w-full
    col-start-1
    col-end-2
    row-start-1
    row-end-6
    md:hidden
    delay-500
    duration-500
    ease-in-out
    opacity-0
    transition-opacity
  `};
  opacity: ${({ show = false }: SideNavModuleContainerProps): string => (show ? '1' : '0')};
  height: fit-content;
  max-height: calc(100vh - 7.5rem);
  max-width: 18rem;
  :after {
    content: '';
    ${tw`
      absolute
      rounded-b-lg
      bottom-1
      left-0
      w-full
      z-10
      pointer-events-none
      h-16
    `}
    background: linear-gradient(rgba(255, 255, 255, 0.001), #f5f5f5 70%);
  }
`;

export const SideNavModuleContent = styled.div`
  ${tw`
    overflow-scroll
    py-2
    my-4
    mx-7
    w-full
  `}
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

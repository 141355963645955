import React, { ReactElement } from 'react';
import { Field, Formik, FormikTouched, FormikValues } from 'formik';

import FormState, { FormStateType } from 'container/FormState';
import Form from 'form/Form';

import Button from 'component/Button/Button';
import FormTextArea from 'component/FormTextArea/FormTextArea';
import FormSelect from 'component/FormSelect/FormSelect';

import { sendFeedback } from 'lib/account';

import { FeedbackFormSchema } from './schema';
import {
  FeedbackFormContainer,
  StyledFeedbackForm,
  FieldContainer,
  ButtonContainer
} from './element';

interface DropdownOption {
  name: string;
  value: string;
}

interface Props {
  onSuccess: () => void;
  formLabel?: string;
  dropdownPlaceholder?: string;
  dropdownOptions: DropdownOption[];
  formPlaceholder?: string;
}

const onSubmit = async ({
  category,
  comment,
  formState,
  onSuccess
}: {
  category: string,
  comment: string,
  formState: FormStateType,
  onSuccess: () => void
}) => {
  const { setErrorMessage } = formState;

  try {
    await sendFeedback({ subject: category, text: comment });
    onSuccess();
  } catch (error) {
    setErrorMessage(error.message);
  }
};

const Buttons = ({
  submitForm,
  formLabel
}: {
  submitForm: () => void,
  touched: FormikTouched<FormikValues>,
  formLabel?: string
}) => (
  <ButtonContainer>
    <Button theme="PrimaryWhite" size="Small" onClick={submitForm}>
      {formLabel}
    </Button>
  </ButtonContainer>
);

export const FeedbackForm = ({
  onSuccess,
  formLabel,
  dropdownPlaceholder,
  dropdownOptions = [],
  formPlaceholder
}: Props): ReactElement => {
  const options = [...[{ name: dropdownPlaceholder, value: '' }], ...dropdownOptions];

  const formState = FormState.useContainer();

  return (
    <FeedbackFormContainer>
      <Formik
        initialValues={{
          category: '',
          comment: ''
        }}
        validationSchema={FeedbackFormSchema}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            formState,
            onSuccess
          });
        }}>
        {({ submitForm, touched, errors }) => {
          return (
            <StyledFeedbackForm>
              <Form errors={errors} touched={touched}>
                <>
                  <FormSelect name="category" options={options} />
                  <FieldContainer>
                    <Field
                      id="comment"
                      aria-label={formPlaceholder}
                      spellCheck={false}
                      component={FormTextArea}
                      name="comment"
                      placeholder={formPlaceholder}
                      withoutBorder
                    />
                  </FieldContainer>
                  <Buttons submitForm={submitForm} touched={touched} formLabel={formLabel} />
                </>
              </Form>
            </StyledFeedbackForm>
          );
        }}
      </Formik>
    </FeedbackFormContainer>
  );
};

export default FeedbackForm;

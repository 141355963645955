import { ReactElement } from 'react';

import BlockFullWithAccentsModule from 'module/BlockFullWithAccentsModule';
import BlockTwoUpModule from 'module/BlockTwoUpModule';
import BlockTwoUpDiagonalModule from 'module/BlockTwoUpDiagonalModule';
import FeedbackModule from 'module/FeedbackModule';
import LoginModule from 'module/LoginModule';
import PrevNextHandlingModule from 'module/PrevNextHandlingModule';
import ResourcesListModule from 'module/ResourcesListModule';
import ResourcesSummaryModule from 'module/ResourcesSummaryModule';
import SectionImageAboveModule from 'module/SectionImageAboveModule';
import SectionModule from 'module/SectionModule';
import SectionTextImageModule from 'module/SectionTextImageModule';
import SectionTextOnlyModule from 'module/SectionTextOnlyModule';
import ShowCaseModule from 'module/ShowCaseModule';
import SideNavModule from 'module/SideNavModule';
import HeroModule from 'module/HeroModule';
import SectionToneOfVoiceModule from 'module/SectionToneOfVoiceModule';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const CONTENT_MODULE_MAPPING: { [key: string]: (props: any) => ReactElement } = {
  BlockFullWithAccentsModule,
  BlockTwoUpModule,
  BlockTwoUpDiagonalModule,
  FeedbackModule,
  LoginModule,
  PrevNextHandlingModule,
  ResourcesListModule,
  ResourcesSummaryModule,
  SectionImageAboveModule,
  SectionModule,
  SectionTextImageModule,
  SectionTextOnlyModule,
  ShowCaseModule,
  SideNavModule,
  HeroModule,
  SectionToneOfVoiceModule
};

import React, { ReactElement } from 'react';
import { FormikProps, Formik } from 'formik';

import Form from 'form/Form';

import { FormStateType } from 'container/FormState';

import { ForgotPasswordEmailFormContent } from './ForgotPasswordEmailFormContent';
import { ForgotPasswordFormSchema } from './schema';
import { StyledForgotPasswordEmailForm } from './element';

interface Values {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (props: {
    email: string,
    formState: FormStateType,
    successHandler: () => void
  }) => void;
  formState: FormStateType;
  setForgotSuccess: (e: boolean) => void;
  setTargetForm: (e: string) => void;
  formSubmitLabel: string;
  formBackLabel: string;
}

export const ForgotPasswordForm = ({
  onSubmit,
  formState,
  setForgotSuccess,
  setTargetForm,
  formSubmitLabel,
  formBackLabel
}: Props): ReactElement => (
  <Formik
    initialValues={{
      email: '',
      password: ''
    }}
    validationSchema={ForgotPasswordFormSchema}
    onSubmit={({ email }, actions) => {
      onSubmit({ email, formState, successHandler: () => setForgotSuccess(true) });
      actions.setSubmitting(false);
      actions.resetForm();
    }}>
    {({ errors, touched, submitForm }: FormikProps<Values>) => (
      <StyledForgotPasswordEmailForm>
        <Form errors={errors} touched={touched}>
          <ForgotPasswordEmailFormContent
            setTargetForm={setTargetForm}
            formSubmitLabel={formSubmitLabel}
            formBackLabel={formBackLabel}
            submitForm={submitForm}
          />
        </Form>
      </StyledForgotPasswordEmailForm>
    )}
  </Formik>
);

export default ForgotPasswordForm;

import tw, { styled } from 'twin.macro';

import { sectionByAspectRatio } from 'config/design';

interface SectionTextOnlyModuleContainerProps {
  aspectRatio?: AspectRatioType;
  show?: boolean;
}

interface SectionTextOnlyModuleColumnProps {
  orientation?: OrientationPropType;
  index: number;
}

const order = {
  '0': {
    left: tw`order-1 sm:order-1`,
    right: tw`order-2 sm:order-2`
  },
  '1': {
    left: tw`order-2 sm:order-2`,
    right: tw`order-1 sm:order-1`
  }
};

export const SectionTextOnlyModuleContainer = styled.div<SectionTextOnlyModuleContainerProps>(
  ({ aspectRatio = '1/1', show = false }: SectionTextOnlyModuleContainerProps) => [
    tw`
      grid
      grid-flow-col
      gap-6
      rounded-cardRadius
      w-full
      sm:grid-flow-row
      sm:gap-4
      sm:pt-4
      delay-700
      duration-500
      ease-in-out
      opacity-0
      transition-opacity
    `,
    show && tw`opacity-100`,
    sectionByAspectRatio[aspectRatio]
  ]
);

export const SectionTextOnlyModuleTextContainer = styled.div(
  ({ orientation = 'left', index }: SectionTextOnlyModuleColumnProps) => [
    tw`
      flex
      justify-start
      sm:px-1
    `,
    order[index][orientation]
  ]
);

export const SectionTextOnlyModuleMarkdownContainer = styled.div``;

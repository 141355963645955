import React, { ReactElement } from 'react';
import { CONTENT_MODULE_MAPPING } from 'config/contentModule';
import { isRoleDisallowed } from 'lib/role';

//-------------------------------------------------------------
// get matching module from registry and render with props
//-------------------------------------------------------------
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const renderTargetModule = (
  module: any,
  idx: number,
  props: any,
  currentRole?: string
): ReactElement | null => {
  if (!CONTENT_MODULE_MAPPING[module.type]) {
    return null;
  }

  if (isRoleDisallowed({ currentRole, module })) {
    return null;
  }

  const TargetModule = CONTENT_MODULE_MAPPING[module.type];
  return (
    <TargetModule
      key={`${module.type}-${idx}`}
      idx={idx}
      {...module}
      {...props}
      currentRole={currentRole}
    />
  );
};

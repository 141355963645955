import React, { ReactElement } from 'react';

import { ShowCaseModuleTagsContainer, ShowCaseModuleTag } from './element';

type Tag = {
  displayName: string,
  name: string
};

interface Props {
  tags?: Tag[];
  tagSelected: string;
  onClickHandler: (props: { [k: string]: string | ImageType }) => void;
}

export const ShowCaseModuleTags = ({
  tags = [],
  tagSelected,
  onClickHandler
}: Props): ReactElement => (
  <ShowCaseModuleTagsContainer>
    {tags.map(({ displayName, name }: { displayName: string, name: string }, idx: number) => (
      <ShowCaseModuleTag
        key={`ShowCaseModuleTagItem-${idx}`}
        state={tagSelected === name ? 'selected' : 'default'}
        onClick={() => onClickHandler({ name })}>
        {displayName}
      </ShowCaseModuleTag>
    ))}
  </ShowCaseModuleTagsContainer>
);

export default ShowCaseModuleTags;

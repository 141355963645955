import tw, { styled } from 'twin.macro';
import { Form } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getDesignSystemValue } = require('config/tailwind.config');

export const FeedbackFormContainer = styled.div`
  ${tw`
    flex
    flex-col
    h-full
    w-full
  `}

  form > div {
    ${tw`rounded-pillRadius`}
  }
`;

export const FieldContainer = styled.div`
  ${tw`
    flex
    mt-3
  `}
`;

export const ButtonContainer = styled.div`
  ${tw`
    flex
    mt-6
    sm:mt-3
  `}
`;

export const StyledFeedbackForm = styled(Form)`
  ${tw`
    w-full
  `}
  label {
    ${tw`
      mb-3
      relative
      text-white
      p-2
      flex
      rounded-cardRadius
    `}
    ${({ hasErrors = false }: { hasErrors?: boolean }): string =>
      hasErrors ? `background-color: ${getDesignSystemValue('colors.wazeRed')}` : ''}
  }

  span {
    ${tw`
    w-full
    relative
    block
    mb-5
    `};
  }
`;

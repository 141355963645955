import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import { HeroModuleContainer, HeroModuleContent } from './element';
import { createBlocks } from 'utils';

import BlockItem from 'component/BlockItem/BlockItem';

interface Props {
  sections?: Block[];
  sidenav: boolean;
  login?: boolean;
}

export const HeroModule = ({ login = false, sidenav, sections }: Props): ReactElement => {
  const blocks = createBlocks(1, sections);

  const [ref, inView] = useInView({ rootMargin: '400px 0px', triggerOnce: true });

  return (
    <HeroModuleContainer
      backgroundColor={blocks[0].backgroundColor}
      login={login}
      sidenav={sidenav}
      ref={ref}
      show={inView}>
      <HeroModuleContent>
        <BlockItem
          theme={blocks[0].theme}
          captionScale={blocks[0].captionScale}
          foregroundColor={blocks[0].foregroundColor}
          heading={blocks[0].header}
          body={blocks[0].body}
          image={blocks[0].image}
          wazer={blocks[0].wazer}
          align={blocks[0].align}
          buttons={blocks[0].buttons}
          withLayout
        />
      </HeroModuleContent>
    </HeroModuleContainer>
  );
};

export default HeroModule;

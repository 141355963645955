import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer';

import BlockItem from 'component/BlockItem/BlockItem';
import BlockWithDecorators from 'component/BlockWithDecorators/BlockWithDecorators';

import LoginWithEmailForm from 'form/LoginWithEmailForm';
import SignUpWithEmailForm from 'form/SignUpWithEmailForm';
import ForgotPasswordEmailForm from 'form/ForgotPasswordEmailForm';
import MainLoginForm from 'form/MainLoginForm';
import FormState from 'container/FormState';

import {
  LoginBrandPermissionContainer,
  LoginModuleContainer,
  LoginModuleLoginForm
} from './element';
import AuthContext from 'AuthContext';

/* eslint-disable @typescript-eslint/no-explicit-any */
const getFormProps = ({ sections = [], targetForm }: { sections: any[], targetForm: string }) =>
  sections.find(({ form }: { form?: string }) => targetForm === form);

/* eslint-disable @typescript-eslint/no-explicit-any */
const getFirstBlockItemProps = ({ sections = [] }: { sections: any[] }) =>
  sections.find(({ type }: { type?: string }) => type === 'BlockItem');

const TARGET_FORM_MAPPING = {
  LoginWithEmailForm: LoginWithEmailForm,
  SignUpWithEmailForm: SignUpWithEmailForm,
  ForgotPasswordEmailForm: ForgotPasswordEmailForm,
  MainLoginForm: MainLoginForm
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const LoginModuleInner = (props: any): ReactElement => {
  const [targetForm, setTargetForm] = useState('MainLoginForm');

  const { isLoadingInitial } = useContext(AuthContext);
  const [ref, inView] = useInView({ triggerOnce: true });

  const formState = FormState.useContainer();
  useEffect(() => {
    // Reset error message on targetForm change
    formState.setErrorMessage('');
  }, [targetForm]);

  const getTargetForm = ({ setTargetForm }: { setTargetForm: (formName: string) => void }) => {
    const FoundForm = TARGET_FORM_MAPPING[targetForm];
    const foundFormProps = getFormProps({ sections: get(props, 'sections'), targetForm });
    return <FoundForm setTargetForm={setTargetForm} {...foundFormProps} />;
  };

  const defaultFormProps = getFormProps({ sections: get(props, 'sections'), targetForm });
  const permissionContainerProps = getFirstBlockItemProps({ sections: get(props, 'sections') });

  return (
    <>
      <LoginModuleContainer
        fullGridRowHeight={targetForm !== 'MainLoginForm'}
        ref={ref}
        show={inView}>
        <BlockWithDecorators
          flipHorizontal
          splitRows
          rightDecorator={{ type: 'square', enabled: true }}
          backgroundColor="gray2"
          bottomPadding={false}
          show>
          <LoginModuleLoginForm isLoadingInitial={isLoadingInitial}>
            {targetForm ? (
              getTargetForm({ setTargetForm })
            ) : (
              <MainLoginForm setTargetForm={setTargetForm} {...defaultFormProps} />
            )}
          </LoginModuleLoginForm>
        </BlockWithDecorators>
      </LoginModuleContainer>

      {(!targetForm || targetForm === 'MainLoginForm') && (
        <LoginBrandPermissionContainer show={inView}>
          <BlockItem {...permissionContainerProps} />
        </LoginBrandPermissionContainer>
      )}
    </>
  );
};

const LoginModule = (props: any): ReactElement => {
  return (
    <FormState.Provider>
      <LoginModuleInner {...props} />
    </FormState.Provider>
  );
};

export default LoginModule;

import tw, { styled } from 'twin.macro';
import { Form } from 'formik';

export const SignUpWithEmailFormContainer = styled.div`
  ${tw`
    flex
    flex-col
    items-center
    justify-center
    z-10
  `}

  div {
    ${tw`pt-2 pb-1`}
  }

  z-index: 100000;
  @media not all and (min-resolution: 0.001dpcm) {
    div:first-child {
      ${tw`ml-0 gap-0 block relative mb-0`}
    }
  }
`;

export const StyledSignUpWithEmailForm = styled(Form)`
  ${tw`flex flex-col pb-7`}
  div {
    ${tw`pb-1 w-64`}
  }
`;

export const SignupFormButtonContainer = styled.div`
  a {
    ${tw`mb-4`}
  }
`;

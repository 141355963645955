import { ReactElement } from 'react';

import DownloadArrow from './DownloadArrow';
import GoogleLogo from './GoogleLogo';

export * from './DownloadArrow';
export * from './GoogleLogo';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ILLUSTRATIONS: { [key: string]: (props: any) => ReactElement } = {
  DownloadArrow,
  GoogleLogo
};

export default ILLUSTRATIONS;

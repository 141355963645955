import * as Yup from 'yup';

const CATEGORY_REQUIRED_ERROR_MESSAGE = `Oops. Please select feedback category from the dropdown. `;
const COMMENT_MININUM_ERROR_MESSAGE = `Oh my that's short. Please tell us a bit more. `;
const COMMENT_MAXIMUM_ERROR_MESSAGE = `You sure have a lot to say here! Please limit the feedback to no more than 300 characters, thanks! `;
const COMMENT_REQUIRED_ERROR_MESSAGE = `Please tell us what's on your mind. `;

export const FeedbackFormSchema = Yup.object().shape({
  category: Yup.string().required(CATEGORY_REQUIRED_ERROR_MESSAGE),
  comment: Yup.string()
    .min(4, COMMENT_MININUM_ERROR_MESSAGE)
    .max(300, COMMENT_MAXIMUM_ERROR_MESSAGE)
    .required(COMMENT_REQUIRED_ERROR_MESSAGE)
});

export default FeedbackFormSchema;

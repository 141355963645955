import React, { ReactElement } from 'react';
import SectionImageAboveModule from 'module/SectionImageAboveModule';
import SectionTextImageModule from 'module/SectionTextImageModule';
import SectionTextOnlyModule from 'module/SectionTextOnlyModule';
import SectionToneOfVoiceModule from 'module/SectionToneOfVoiceModule';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { SectionModuleContainer } from './element';


const SECTION_MODULE_WHITELIST = {
  SectionImageAboveModule: SectionImageAboveModule,
  SectionTextImageModule: SectionTextImageModule,
  SectionTextOnlyModule: SectionTextOnlyModule,
  SectionToneOfVoiceModule: SectionToneOfVoiceModule
};

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  sidenav: boolean;
  sections: any[];
  backgroundColor?: string;
  sectionId?: string;
}

const getSectionModule = ({ moduleName }: { moduleName: string }) =>
  get(SECTION_MODULE_WHITELIST, moduleName, null);

const getSectionComponent = ({ module, idx }: { module: { type: string }, idx: number }) => {
  const { type: moduleName } = module;
  const TargetModule = getSectionModule({ moduleName });
  return <TargetModule key={`section-module-${moduleName}-${idx}`} {...module} />;
};

export const SectionModule = ({
  sectionId = '',
  sidenav,
  sections = [],
  backgroundColor = 'gray'
}: Props): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <SectionModuleContainer
      backgroundColor={backgroundColor}
      sidenav={sidenav}
      id={sectionId}
      ref={ref}
      show={inView}>
      {sections.map((module: { type: string }, idx: number) =>
        getSectionComponent({ module, idx })
      )}
    </SectionModuleContainer>
  );
};

export default SectionModule;


// const SectionModule = ({
//     sectionId = '',
//     // @ts-ignore
//     sidenav,
//     sections = [],
//     backgroundColor = 'gray'
// }) => {
//   const [ref, inView] = useInView({ triggerOnce: true });

//   // console.log('SECTIONS', sections);
//   console.log('sidenav', sidenav);

//   console.log('background color', backgroundColor);


//   return (
//     <SectionModuleContainer
//       backgroundColor={backgroundColor}
//       sidenav={sidenav}
//       id={sectionId}
//       ref={ref}
//       show={inView}
//     >

//     </SectionModuleContainer>
//   )
// }

// export default SectionModule;
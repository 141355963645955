import tw, { styled } from 'twin.macro';

interface SectionImageAboveModuleContainerProps {
  show?: boolean;
}

export const SectionImageAboveModuleContainer = styled.div<SectionImageAboveModuleContainerProps>(
  ({ show = false }) => [
    tw`
    grid
    grid-flow-row
    gap-4
    rounded-cardRadius
    w-full
    sm:grid-flow-row
    sm:pb-4
    delay-700
    duration-500
    ease-in-out
    opacity-0
    transition-opacity
    `,
    show && tw`opacity-100`
  ]
);

export const SectionImageAboveModuleImagesContainer = styled.div`
  ${tw`
    grid
    gap-6
    grid-flow-col
    justify-center
    sm:grid-flow-row
    sm:gap-4
  `};
  grid-auto-columns: 1fr;
  justify-items: center;
`;

export const SectionImageAboveModuleTextContainer = styled.div`
  ${tw`
    grid
    gap-4
    grid-flow-col
    grid-cols["1fr 3fr"]
    justify-center
    sm:grid-flow-row
    sm:grid-cols-none
    sm:gap-2
    sm:px-1
  `};
`;

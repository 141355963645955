import React, { ReactElement, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { reduce } from 'lodash';

import { ShowCaseModuleContainer, DropdownContainer } from './element';
import ShowCaseModuleMasonry from './ShowCaseModuleMasonry';
import ShowCaseModuleTags from './ShowCaseModuleTags';
import { Modal, Dropdown } from 'component';
import ShowCaseModuleItem from './ShowCaseModuleItem';

const getDropdownOptions = ({
  tags,
  onClickHandler
}: {
  tags: Tag[],
  onClickHandler: (props: { [e: string]: string }) => void
}) =>
  reduce(
    tags,
    (nextTags: Tag[], tag: Tag) => [
      ...nextTags,
      { displayName: tag.displayName, codename: tag.name, onClickHandle: onClickHandler }
    ],
    []
  );

const getModalContent = ({
  image,
  audio,
  video,
  title,
  setIsOpen
}: {
  image: ImageType,
  audio?: AudioType,
  video?: VideoType,
  title: string,
  setIsOpen: (e: boolean) => void
}) => (
  <>
    <ShowCaseModuleItem
      image={image}
      audio={audio}
      video={video}
      title={title}
      modalIsOpen={true}
      toggleModal={() => setIsOpen(false)}
    />
  </>
);

export interface Tag {
  displayName: string;
  name: string;
}

export interface ShowCaseItem {
  title: string;
  image: {
    path: string
  };
  audio?: {
    path: string
  };
  video?: {
    path: string
  };
  tag: string;
}

interface Props {
  sidenav: boolean;
  showCaseItems: ShowCaseItem[];
  tags: Tag[];
}

/* eslint-disable max-lines-per-function */
export const ShowCaseModule = ({
  sidenav = false,
  showCaseItems = [],
  tags = []
}: Props): ReactElement => {
  const [targetShowCaseItems, setTargetShowCaseItems] = useState(showCaseItems || []);
  const [tagSelected, setTagSelected] = useState('all');
  const [modalContent, setModalContent] = useState<ReactElement>(<div />);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showMasonry, setShowMasonry] = useState(true);

  const toggleModal = ({
    image,
    audio,
    video,
    title
  }: {
    image: ImageType,
    audio: AudioType,
    video: VideoType,
    title: string
  }) => {
    setIsOpen(!modalIsOpen);

    const modalContent = getModalContent({ image, audio, video, title, setIsOpen });

    setModalContent(modalContent);
  };

  const onClickHandler = ({ name }: { name: string }) => {
    const MASONRY_SWAP_CONTENT_DELAY = 750;
    const MASONRY_SHOW_FRESH_CONTENT_DELAY = 1000;

    setTagSelected(name);
    setShowMasonry(false);

    if (name === 'all') {
      setTimeout(() => setTargetShowCaseItems(showCaseItems), MASONRY_SWAP_CONTENT_DELAY);
    } else {
      setTimeout(
        () =>
          setTargetShowCaseItems(
            showCaseItems.filter((item: ShowCaseItem) => item.tag.includes(name))
          ),
        MASONRY_SWAP_CONTENT_DELAY
      );
    }
    setTimeout(() => setShowMasonry(true), MASONRY_SHOW_FRESH_CONTENT_DELAY);
  };

  const dropdownOptions = getDropdownOptions({ tags, onClickHandler });

  const [ref, inView] = useInView({ rootMargin: '250px 0px', triggerOnce: true });

  return (
    <ShowCaseModuleContainer sidenav={sidenav} ref={ref} show={inView}>
      <ShowCaseModuleTags tags={tags} tagSelected={tagSelected} onClickHandler={onClickHandler} />
      <DropdownContainer>
        <Dropdown
          options={dropdownOptions}
          initialOptionCodenameSelected={tagSelected}
          onClickHandle={onClickHandler}
        />
      </DropdownContainer>
      <ShowCaseModuleMasonry
        show={showMasonry}
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        showCaseItems={targetShowCaseItems}
      />
      <Modal modalIsOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)}>
        {modalContent}
      </Modal>
    </ShowCaseModuleContainer>
  );
};
export default ShowCaseModule;

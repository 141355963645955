import tw, { css, styled } from 'twin.macro';

import { getDesignSystemValue } from 'config/design';

interface FeedbackModuleContainerProps {
  sidenav: boolean;
  backgroundColor: string;
  show?: boolean;
}

export const FeedbackModuleContainer = styled.div(
  ({ sidenav, backgroundColor, show = false }: FeedbackModuleContainerProps) => [
    tw`
      w-full
      rounded-cardRadius
      p-9
      md:col-auto
      sm:p-3
      sm:py-9
      delay-700
      duration-500
      ease-in-out
      opacity-0
      transition-opacity
    `,
    sidenav
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    css`
      background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
    `,
    show && tw`opacity-100`
  ]
);

export const FeedbackModuleContent = styled.div`
  ${tw`
    grid
    grid-flow-col
    grid-cols["1fr 2fr"]
    gap-9
    items-start
    sm:grid-flow-row
    sm:grid-cols-none
    sm:gap-3
  `}
`;

export const FeedbackModuleBlockContainer = styled.div``;

export const FeedbackModuleFormContainer = styled.div``;

export const ImageContainer = styled.div`
  ${tw`w-32 absolute left-25 md:hidden`}
`;

export const FeedbackSuccessContainer = styled.div`
  ${tw`
  w-full
  delay-700
  duration-500
  ease-in-out
  transition-opacity
  col-start-1
  col-end-3
  `}
`;

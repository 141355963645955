/* eslint-disable max-lines, max-lines-per-function */
import tw, { styled, css, TwStyle } from 'twin.macro';
import { SimpleInterpolation } from 'styled-components';

import { paragraphScales } from 'config/design';

interface ShowCaseModuleContainerProps {
  sidenav: boolean;
  show?: boolean;
}

export const ShowCaseModuleContainer = styled.div<ShowCaseModuleContainerProps>(
  ({ sidenav, show = false }: ShowCaseModuleContainerProps) => [
    tw`
      grid
      grid-flow-row
      gap-3
      w-full
      sm:col-auto
      sm:gap-2
      delay-700
      duration-700
      ease-in-out
      opacity-0
      transition-all
      translate-y-4
      transform
    `,
    show && tw`opacity-100`,
    show ? tw`translate-y-0` : tw`translate-y-4`,
    sidenav
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    css`
      .showcase-masonry-grid {
        ${tw`
          flex
          w-full
        `}
      }
      .showcase-masonry-grid-column {
        background-clip: padding-box;
      }
      .showcase-masonry-grid-column:last-child {
        ${tw`
          ml-3
          sm:ml-2
        `}
      }
      .showcase-masonry-grid-column:first-child {
        ${tw`
          ml-0
        `}
      }
      .showcase-masonry-grid-column > div {
        ${tw`
          bg-gray
          mb-3
          p-9
          sm:p-4
          sm:mb-2
          rounded-cardRadius
        `}
      }
      .showcase-masonry-grid-column > div > div {
        ${tw`
          p-0
          mt-3
          w-full
          sm:mt-2
          sm:hidden
        `}
      }
      .showcase-masonry-grid-column > div:last-child {
        ${tw`
          mb-0
        `}
      }
      .showcase-masonry-grid-column > div > img {
        ${tw`
          sm:w-full
          sm:rounded-cardRadius
        `}
      }
      .showcase-masonry-grid-column > div > div > p {
        ${paragraphScales.Med}
        ${paragraphScales.mobile.Small}
      }
    `
  ]
);

interface ShowCaseModuleItemContainerProps {
  modalIsOpen?: boolean;
  show?: boolean;
  idx?: number;
  isEmpty: boolean;
}

/* stylelint-disable selector-type-no-unknown */
export const ShowCaseModuleItemContainer = styled.div`
  ${tw`
  flex
  flex-col
  shadow-none
  items-center
  bg-gray
  p-10
  font-boing
  border
  rounded-cardRadius
  cursor-pointer
  justify-center
  duration-300
  ease-in-out
  transition-all
  translate-y-4
  transform
  `}

  ${({ modalIsOpen }: ShowCaseModuleItemContainerProps) =>
    modalIsOpen
      ? css`
          img {
            min-height: 80%;
            max-height: 80%;
            ${tw`
          sm:h-auto
          sm:min-h-0
          sm:px-3
        `}
          }
          ${tw`
          sm:p-0
          mb-8
          delay-700
          duration-500
          cursor-default
          ease-in-out
          transition-opacity`}
        `
      : ''}

  ${({ idx }: ShowCaseModuleItemContainerProps): TwStyle | SimpleInterpolation =>
    css`
      transition-delay: ${idx ? (idx < 4 ? 250 : 1000) : 250}ms;
    `}

  ${({ isEmpty }: ShowCaseModuleItemContainerProps): TwStyle | SimpleInterpolation =>
    css`
      background-color: ${isEmpty ? 'transparent !important' : ''};
      padding: ${isEmpty ? '0px !important' : ''};
    `}

  ${({ show }: ShowCaseModuleItemContainerProps): TwStyle =>
    show ? tw`opacity-100` : tw`opacity-0`}

  ${({ show }: ShowCaseModuleItemContainerProps): TwStyle =>
    show ? tw`translate-y-0` : tw`translate-y-4`}
`;

export const ShowCaseModuleTagContainer = styled.div``;

export const ShowCaseModuleTagsContainer = styled.div`
  ${tw`
    grid
    grid-flow-col
    gap-3
    w-full
    sm:hidden
  `}
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
`;

interface ShowCaseModuleTagProps {
  state?: 'default' | 'selected';
}

const tagStylesByState = {
  default: tw`
    bg-gray
  `,
  selected: tw`
    bg-white
    border-black
  `
};

export const ShowCaseModuleTag = styled.button(({ state = 'default' }: ShowCaseModuleTagProps) => [
  tw`
    box-border
    border-solid
    font-boing
    font-medium
    max-w-80
    border-3
    border-transparent
    no-underline
    rounded-cardRadius
    h-13
    outline-none
    focus:outline-none
    hover:border-black
  `,
  tagStylesByState[state],
  css`
    transition: border 250ms ease-in-out;
  `
]);

export const ShowcaseModuleModalCloseButtonContainer = styled.div`
  ${tw`
  absolute
  top-6
  right-6
  `}
`;

export const DropdownContainer = styled.div`
  ${tw`
    hidden
    sm:block
  `}
`;

export const ShowcaseCloseButton = styled.button`
  ${tw`
    flex
    box-border
    border-solid
    font-boing
    font-medium
    items-center
    max-w-80
    border-3
    rounded-full
    border-transparent
    no-underline
    hover:border-black
    bg-white
    text-black
    h-12
    min-w-28
    ms-xl
    px-5
  `};
  transition: all 250ms ease-in-out;
`;

export const MasonryContainer = styled.div(({ show = true }: { show?: boolean }) => [
  tw`
      duration-700
      ease-in-out
      transition-opacity
  `,
  show ? tw`opacity-100` : tw`opacity-0`
]);

import tw, { styled } from 'twin.macro';

interface TwoUpNxNBlockProps {
  columns?: string;
}

export const TwoUpNxNContainer = styled.div`
  ${tw`
    grid
    grid-flow-col
    grid-cols-12
    gap-3
    w-full
    rounded-cardRadius
    col-start-1
    col-end-13
    sm:grid-rows-2/auto
    sm:grid-flow-row
    sm:grid-cols-none
    sm:col-start-auto
    sm:col-end-auto
    sm:gap-2
  `};
`;

const blockGridColumns = {
  '1': tw`col-span-1 sm:col-auto`,
  '2': tw`col-span-2 sm:col-auto`,
  '3': tw`col-span-3 sm:col-auto`,
  '4': tw`col-span-4 sm:col-auto`,
  '5': tw`col-span-5 sm:col-auto`,
  '6': tw`col-span-6 sm:col-auto`,
  '7': tw`col-span-7 sm:col-auto`,
  '8': tw`col-span-8 sm:col-auto`,
  '9': tw`col-span-9 sm:col-auto`,
  '10': tw`col-span-10 sm:col-auto`,
  '11': tw`col-span-11 sm:col-auto`,
  '12': tw`col-span-12 sm:col-auto`
};

export const TwoUpNxNBlock = styled.div(({ columns }: TwoUpNxNBlockProps) => [
  blockGridColumns[columns || '6']
]);

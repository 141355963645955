import tw, { styled } from 'twin.macro';

export const StyledErrorMessage = styled.span`
  ${tw`
  p-3
  bg-wazeRed
  text-white
  z-10
  w-64
  rounded-cardRadius
  delay-500
  duration-700
  ease-in-out
  transition-all
  translate-y-2
  transform
  `};
`;

import React, { ReactElement } from 'react';
import { Field } from 'formik';

import { Button as FormButton, InputTextField } from 'component';

import { ForgotPasswordEmailFormButtonContainer } from './element';

interface Props {
  submitForm: (() => Promise<void>) & (() => Promise<void>);
  formSubmitLabel?: string;
  formBackLabel?: string;
  setTargetForm: (e: string) => void;
}

export const ForgotPasswordEmailFormContent = ({
  submitForm,
  formSubmitLabel,
  formBackLabel,
  setTargetForm
}: Props): ReactElement => (
  <>
    <Field
      aria-label="Email"
      autoComplete="email"
      spellCheck={false}
      component={InputTextField}
      name="email"
      placeholder={'Email'}
      type="email"
    />
    <ForgotPasswordEmailFormButtonContainer>
      <FormButton size="Small" onClick={submitForm}>
        {formSubmitLabel}
      </FormButton>
      <FormButton size="Small" theme="PrimaryWhite" onClick={() => setTargetForm('MainLoginForm')}>
        {formBackLabel}
      </FormButton>
    </ForgotPasswordEmailFormButtonContainer>
  </>
);

export default ForgotPasswordEmailFormContent;

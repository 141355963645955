import React, { ReactElement, ReactNode } from 'react';

import { Container, Block } from './element';

import Box from 'component/Box';

// type FixedTwoArray<T> = [T, T];

interface TwoUpNxNDiagonalPropsType {
  colors: string[];
  orientation?: 'right' | 'left';
  columns?: string;
  children?: ReactNode[];
  show?: boolean;
  shrinkHeight?: boolean;
}

const directions = {
  '0': 'left',
  '1': 'right'
};

export const TwoUpNxNDiagonal = ({
  colors,
  orientation = 'right',
  columns = '6/6',
  children = [null, null],
  show,
  shrinkHeight = false
}: TwoUpNxNDiagonalPropsType): ReactElement => {
  const columnsValue = columns.split('/');
  return (
    <Container>
      {children.map((child: ReactNode, index: number) => (
        <Block
          key={`block_box_${index}`}
          columns={columnsValue[index]}
          show={show}
          idx={index}
          shrinkHeight={shrinkHeight}>
          <Box
            direction={directions[index]}
            backgroundColor={colors[index]}
            orientation={orientation}
            decorators={{
              left: { name: index === 0 ? 'rectangle' : 'diagonal' },
              right: { name: index === 0 ? 'diagonal' : 'rectangle' }
            }}
            shrinkHeight={shrinkHeight}>
            {child}
          </Box>
        </Block>
      ))}
    </Container>
  );
};

export default TwoUpNxNDiagonal;

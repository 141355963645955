import tw, { css, styled } from 'twin.macro';

import { getDesignSystemValue } from 'config/design';

interface SectionModuleContainerProps {
  sidenav: boolean;
  backgroundColor?: string;
  show?: boolean;
}

export const SectionModuleContainer = styled.div<SectionModuleContainerProps>(
  ({ sidenav, backgroundColor = 'gray', show = false }: SectionModuleContainerProps) => [
    tw`
      grid
      grid-flow-row
      row-gap-9
      col-gap-9
      p-9
      sm:gap-4
      sm:p-2
      rounded-cardRadius
      md:col-auto
      delay-700
      duration-700
      ease-in-out
      opacity-0
      transition-all
      translate-y-4
      transform
      opacity-0
  `,
    sidenav
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    css`
      background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
    `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`
  ]
);

import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { get } from 'lodash';

import { createBlocks, generateColumnsName } from 'utils';
import { BlockTwoUpContainer } from './element';

import TwoUpNxN from 'component/TwoUpNxN';

import BlockWithDecorators from 'component/BlockWithDecorators/BlockWithDecorators';
import BlockItem from 'component/BlockItem/BlockItem';
import Wazer from 'component/Wazer/Wazer';

interface BlockTwoUpModuleProps {
  sidenav: boolean;
  sections?: Block[];
  wazer?: Wazer;
  idx?: number;
}

export const BlockTwoUpModule = ({
  sidenav,
  sections,
  wazer,
  idx
}: BlockTwoUpModuleProps): ReactElement => {
  const blocks = createBlocks(2, sections);
  const columns = generateColumnsName(blocks);

  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <BlockTwoUpContainer sidenav={sidenav} ref={ref} show={inView}>
      <TwoUpNxN columns={columns}>
        {blocks.map((block: Block, index: number) => (
          <BlockWithDecorators
            idx={index}
            show={inView}
            key={`block_decorator_${index}`}
            leftDecorator={block.leftDecorator}
            rightDecorator={block.rightDecorator}
            backgroundColor={block.backgroundColor}
            twoUpPadding={true}
            wazer={block.wazer}>
            <BlockItem
              theme={block.theme}
              foregroundColor={block.foregroundColor}
              captionScale={block.captionScale}
              heading={block.header}
              body={block.body}
              image={block.image}
              align={block.align}
              buttons={block.buttons}
              animation={block.animation}
              idx={idx}
            />
          </BlockWithDecorators>
        ))}
      </TwoUpNxN>
      <Wazer
        image={get(wazer, 'image')}
        direction={get(wazer, 'direction', 'left')}
        position={get(wazer, 'position')}
        animate={get(wazer, 'animate')}
      />
    </BlockTwoUpContainer>
  );
};

export default BlockTwoUpModule;

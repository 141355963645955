import React, { ReactElement } from 'react';
import { FormikProps, Formik, FormikHelpers } from 'formik';

import {
  SIGNUP_FORM_TITLE,
  SIGNUP_FORM_DESCRIPTION_MARKDOWN,
  SIGNUP_FORM_SUBMIT_LABEL,
  SIGNUP_FORM_BACK_LABEL,
  firebase as firebaseConfig
} from 'config';

import FormState, { FormStateType } from 'container/FormState';
import BlockItem from 'component/BlockItem/BlockItem';

import { signUp } from 'lib/account';

import { SignUpForm } from './SignUpForm';
import { SignupWithEmailFormSchema } from './schema';
import { SignUpWithEmailFormContainer, StyledSignUpWithEmailForm } from './element';

interface Values {
  email: string;
  password: string;
}

interface Props {
  formTitle?: string;
  formDescriptionMarkdown?: string;
  formSubmitLabel?: string;
  formBackLabel?: string;
  setTargetForm: (fileName: string) => void;
  formState?: FormStateType;
}

const onSubmit = async (
  {
    email,
    password,
    formState
  }: {
    email: string,
    password: string,
    formState: FormStateType
  },
  actions: FormikHelpers<{
    email: string,
    password: string
  }>
) => {
  const { loginRedirectPath } = firebaseConfig;
  const { setErrorMessage } = formState;

  try {
    await signUp({ email, password, formState, loginRedirectPath });
    actions.setSubmitting(false);
  } catch (error) {
    setErrorMessage(error.message);
  }
  actions.resetForm();
};

export const SignUpWithEmailForm = ({
  formTitle = SIGNUP_FORM_TITLE,
  formDescriptionMarkdown = SIGNUP_FORM_DESCRIPTION_MARKDOWN,
  formSubmitLabel = SIGNUP_FORM_SUBMIT_LABEL,
  formBackLabel = SIGNUP_FORM_BACK_LABEL,
  setTargetForm
}: Props): ReactElement => {
  const formState = FormState.useContainer();

  return (
    <SignUpWithEmailFormContainer>
      <BlockItem
        theme="BigHeadingText"
        captionScale="S"
        foregroundColor="black"
        heading={formTitle}
        body={formDescriptionMarkdown}
        withLayout
      />
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={SignupWithEmailFormSchema}
        onSubmit={({ email, password }, actions) => {
          onSubmit({ email, password, formState }, actions);
        }}>
        {({ errors, touched, submitForm }: FormikProps<Values>) => (
          <StyledSignUpWithEmailForm>
            <SignUpForm
              formSubmitLabel={formSubmitLabel}
              formBackLabel={formBackLabel}
              setTargetForm={setTargetForm}
              submitForm={submitForm}
              errors={errors}
              touched={touched}
            />
          </StyledSignUpWithEmailForm>
        )}
      </Formik>
    </SignUpWithEmailFormContainer>
  );
};

export default SignUpWithEmailForm;

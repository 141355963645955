import React, { ReactElement } from 'react';
import Masonry from 'react-masonry-css';

import { ShowCaseItem } from './ShowCaseModule';
import ShowCaseModuleItem from './ShowCaseModuleItem';

import { MasonryContainer } from './element';

interface Props {
  showCaseItems?: ShowCaseItem[];
  toggleModal: (props: { [k: string]: string | ImageType }) => void;
  modalIsOpen?: boolean;
  show?: boolean;
}

const MASONRY_BREAKPOINT_COLUMNS = {
  default: 2,
  900: 1
};

const MASONRY_CLASS_NAME = 'showcase-masonry-grid';
const MASONRY_COLUMN_CLASS_NAME = 'showcase-masonry-grid-column';

export const ShowCaseModuleMasonry = ({
  showCaseItems = [],
  toggleModal,
  modalIsOpen,
  show
}: Props): ReactElement => {
  return (
    <MasonryContainer show={show}>
      <Masonry
        breakpointCols={MASONRY_BREAKPOINT_COLUMNS}
        className={MASONRY_CLASS_NAME}
        columnClassName={MASONRY_COLUMN_CLASS_NAME}>
        {showCaseItems.map((item: ShowCaseItem, idx: number) => (
          <ShowCaseModuleItem
            modalIsOpen={modalIsOpen}
            key={`ShowCaseModuleItem-${idx}`}
            idx={idx}
            toggleModal={toggleModal}
            {...item}
          />
        ))}
      </Masonry>
    </MasonryContainer>
  );
};

export default ShowCaseModuleMasonry;

import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { get, isEmpty } from 'lodash';

import { createBlocks, generateColumnsName } from 'utils';
import { Container } from './element';

import Wazer from 'component/Wazer/Wazer';
import TwoUpNxNDiagonal from 'component/TwoUpNxNDiagonal';
import BlockItem from 'component/BlockItem/BlockItem';

interface PropsType {
  sidenav: boolean;
  sections?: Block[];
  orientation?: 'right' | 'left';
  wazer?: Wazer;
  singleColumn?: boolean;
  disableOnMobile?: boolean;
}

export const BlockTwoUpDiagonalModule = ({
  sidenav,
  sections,
  orientation = 'right',
  wazer,
  singleColumn,
  disableOnMobile
}: PropsType): ReactElement => {
  const blocks = createBlocks(2, sections);
  const columns = generateColumnsName(blocks);

  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <Container
      sidenav={sidenav}
      ref={ref}
      show={inView}
      singleColumn={singleColumn}
      disableOnMobile={disableOnMobile}>
      <TwoUpNxNDiagonal
        show={inView}
        columns={columns}
        orientation={orientation}
        shrinkHeight={singleColumn}
        colors={[blocks[0].backgroundColor || 'gray', blocks[1].backgroundColor || 'gray']}>
        {blocks.map((block: Block, index: number) => (
          <BlockItem
            key={`block_${index}`}
            theme={block.theme}
            foregroundColor={block.foregroundColor}
            captionScale={block.captionScale}
            heading={block.header}
            body={block.body}
            image={block.image}
            wazer={block.wazer}
            align={block.align}
            buttons={block.buttons}
            animation={block.animation}
            hasWazer={!isEmpty(wazer)}
          />
        ))}
      </TwoUpNxNDiagonal>
      <Wazer
        image={get(wazer, 'image')}
        direction={get(wazer, 'direction', 'left')}
        position={get(wazer, 'position')}
        animate={get(wazer, 'animate')}
        width={get(wazer, 'width')}
      />
    </Container>
  );
};

export default BlockTwoUpDiagonalModule;

import * as Yup from 'yup';

const LOGIN_WITH_EMAIL_EMAIL_INVALID_ERROR_MESSAGE = `Oops. Please enter a valid email address.`;
const LOGIN_WITH_EMAIL_EMAIL_REQUIRED_ERROR_MESSAGE = `Please enter your email address.`;
const LOGIN_WITH_EMAIL_PASSWORD_REQUIRED_ERROR_MESSAGE = `Please enter your password.`;

export const LoginWithEmailFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(LOGIN_WITH_EMAIL_EMAIL_INVALID_ERROR_MESSAGE)
    .required(LOGIN_WITH_EMAIL_EMAIL_REQUIRED_ERROR_MESSAGE),
  password: Yup.string().required(LOGIN_WITH_EMAIL_PASSWORD_REQUIRED_ERROR_MESSAGE)
});

export default LoginWithEmailFormSchema;

import tw, { css, styled } from 'twin.macro';

interface LoginModuleContainerProps {
  fullGridRowHeight?: boolean;
  show?: boolean;
}

export const LoginModuleContainer = styled.div(
  ({ fullGridRowHeight, show }: LoginModuleContainerProps) => [
    tw`
    font-boing
    row-start-1
    row-end-2
    col-start-2
    col-end-2
    md:col-start-1
    md:col-end-1
    md:row-start-2
    md:row-end-2
    delay-700
    duration-700
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0
  `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`row-end-7` : tw`row-end-2`,
    show ? tw`translate-y-0` : tw`translate-y-4`,
    fullGridRowHeight ? tw`row-start-1 row-end-10` : ``
  ]
);

export const LoginModuleContent = styled.div`
  ${tw`
  flex
  flex-col
  bg-gray
  p-20
  font-boing
  w-2/3
  justify-center
  `}
`;

export const LoginModuleContentTitle = styled.div`
  ${tw`
  text-center
  text-6xl
  font-medium
  text-left
  w-1/2
  leading-tight
  `}
`;

export const LoginModuleContentDescription = styled.div`
  ${tw`
  text-center
  w-1/2
  py-1
  text-2xl
  font-thin
  text-left
  w-2/3
  leading-snug
  `}
`;

export const LoginModuleContentWazer = styled.div`
  ${tw`
  relative
  `}
  left: -14rem;
  bottom: -2rem;
`;

export const LoginModuleLoginForm = styled.div`
  ${tw`
  flex
  flex-col
  bg-transparent
  items-center
  mx-auto
  z-10
  pt-10
  pb-5
`}
  ${({ isLoadingInitial }: { isLoadingInitial?: boolean }) =>
    isLoadingInitial
      ? css`
          opacity: 0.5;
        `
      : ''}
`;

interface LoginBrandPermissionContainerProps {
  show?: boolean;
}
export const LoginBrandPermissionContainer = styled.div(
  ({ show }: LoginBrandPermissionContainerProps) => [
    tw`
    bg-gray2
    rounded-cardRadius
    col-start-2
    col-end-3
    row-start-7
    row-end-10
    md:col-start-1
    md:col-end-1
    md:row-start-3
    md:row-end-3
    delay-1000
    duration-1000
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0
    px-0
    sm:pt-4
    sm:pb-2
  `,
    css`
      div {
        ${tw`py-0`}
      }
    `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`
  ]
);

import React, { ReactElement } from 'react';
import { Field } from 'formik';

import Form from 'form/Form';

import InputTextField from 'component/InputTextField/InputTextField';
import Button from 'component/Button/Button';

import { LoginWithEmailFormButtonContainer } from './element';

interface Props {
  submitForm: () => Promise<void>;
  setTargetForm: (e: string) => void;
  formSubmitLabel: string;
  formBackLabel: string;
}

export const LoginForm = ({
  submitForm,
  setTargetForm,
  formSubmitLabel,
  formBackLabel
}: Props): ReactElement => (
  <Form>
    <>
      <Field
        aria-label="Email"
        autoComplete="email"
        spellCheck={false}
        component={InputTextField}
        name="email"
        placeholder={'Email'}
        type="email"
      />
      <Field
        aria-label="Password"
        spellCheck={false}
        component={InputTextField}
        name="password"
        placeholder={'Password'}
        type="password"
      />
      <LoginWithEmailFormButtonContainer>
        <Button size="Small" onClick={submitForm}>
          {formSubmitLabel}
        </Button>
        <Button size="Small" theme="PrimaryWhite" onClick={() => setTargetForm('MainLoginForm')}>
          {formBackLabel}
        </Button>
      </LoginWithEmailFormButtonContainer>
    </>
  </Form>
);

export default LoginForm;

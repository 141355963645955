import React, { ReactElement } from 'react';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer';

import Image from 'component/Image';
import BlockItem from 'component/BlockItem/BlockItem';

import {
  ResourcesSummaryModuleContainer,
  ResourcesSummaryModuleImageContainer,
  ResourcesSummaryModuleMarkdownContainer,
  ResourcesSummaryItemContainer
} from './element';

export interface Button {
  type?: string;
  link: string;
  text: string;
  theme: ButtonThemeType;
  size: ButtonSizeType;
}

export interface Item {
  description: string;
  button: Button;
  heading: string;
  image: ImageType;
}

export interface Props {
  summaryItems?: Item[];
  backgroundColor?: string;
}

export const ResourcesSummaryModule = ({
  summaryItems = [],
  backgroundColor = 'gray2'
}: Props): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <ResourcesSummaryModuleContainer backgroundColor={backgroundColor} ref={ref} show={inView}>
      {summaryItems.map(({ heading, description, button, image }: Item, idx: number) => (
        <ResourcesSummaryItemContainer key={`ResourcesSummaryItem-${idx}`}>
          <ResourcesSummaryModuleImageContainer>
            <Image width={150} src={get(image, 'path', '')} />
          </ResourcesSummaryModuleImageContainer>
          <ResourcesSummaryModuleMarkdownContainer>
            <BlockItem
              withoutContainer
              foregroundColor="black"
              captionScale="XXS"
              heading={heading}
              body={description}
              buttons={[button]}
            />
          </ResourcesSummaryModuleMarkdownContainer>
        </ResourcesSummaryItemContainer>
      ))}
    </ResourcesSummaryModuleContainer>
  );
};

export default ResourcesSummaryModule;

/* stylelint-disable no-descending-specificity */
/* eslint-disable max-lines */

import tw, { css, styled } from 'twin.macro';

import { getDesignSystemValue } from 'config/design';

export const SectionToneOfVoiceModuleContainer = styled.div``;

export const SectionToneOfVoiceNavContainer = styled.div`
  ${tw`
      flex
      font-semibold
      pb-7
      w-full
      justify-between
      font-boing
      font-medium
      text-black
      no-underline
      leading-9
    `}
`;

interface SectionToneOfVoiceColorItemContainerProps {
  backgroundColor?: string;
}

interface SectionToneOfVoiceNavItemContainerProps {
  selected?: boolean;
  inView: boolean;
  delay: number;
}

export const SectionToneOfVoiceNavItemContentContainer = styled.div`
  ${tw`
    flex
    justify-center
  `}
`;

export const SectionToneOfVoiceNavItemContainer = styled.div<SectionToneOfVoiceNavItemContainerProps>(
  ({ selected = false, inView = false, delay }: SectionToneOfVoiceNavItemContainerProps) => [
    tw`
    cursor-pointer
    duration-300
    ease-in-out
    relative
    opacity-0
  `,
    css`
      width: fit-content;
      :after {
        ${tw`
          bg-black
          absolute
          left-0
          w-0
          duration-300
          ease-in-out
        `};
        content: '';
        bottom: -6px;
        height: 2px;
        transition-property: width;
      }
    `,
    selected &&
      css`
        :after {
          ${tw`w-full`}
        }
      `,
    css`
      :hover:after {
        ${tw`
          w-full
        `};
      }
    `,
    css`
      transform: translateY(${inView ? '0px' : '30px'});
    `,
    css`
      transition: opacity ${delay}ms ease-in-out, transform ${delay / 2}ms ease-in-out;
    `,
    inView && tw`opacity-100`
  ]
);

export const SectionToneOfVoiceColorContainer = styled.div``;
export const SectionToneOfVoiceColorItemContainer =
  styled.div < SectionToneOfVoiceColorItemContainerProps > ``;

export const SectionToneOfVoiceScaleContainer = styled.div`
  ${tw`
    sm:px-3
  `}
`;

interface SectionToneOfVoiceScaleWazerProps {
  imageYPosition: number;
  inView: boolean;
}

export const SectionToneOfVoiceScaleWazer = styled.div<SectionToneOfVoiceScaleWazerProps>(
  ({ inView = false }: SectionToneOfVoiceScaleWazerProps) => [
    tw`
      absolute
      left-0
      bottom-1
      w-12
      h-12
    `,
    css`
      transform: ${({
        imageYPosition = 0,
        inView = false
      }: SectionToneOfVoiceScaleWazerProps): string =>
        `${inView ? `translateX(${imageYPosition}px)` : `translateX(${imageYPosition - 36}px)`}`};
      opacity: ${inView ? '1' : '0'};
      transition: transform 2s cubic-bezier(0.82, 0, 0.13, 1), opacity 100ms ease-in-out 1s;
    `
  ]
);

interface SectionToneOfVoiceScaleDescriptionProps {
  showContent?: boolean;
}

export const SectionToneOfVoiceScaleDescription = styled.div<SectionToneOfVoiceScaleDescriptionProps>(
  ({ showContent = false }: SectionToneOfVoiceScaleDescriptionProps) => [
    tw`
      opacity-0
      pt-9
      pb-3
      max-w-3/4
      text-center
      mx-auto
      duration-300
      ease-in-out
      transition-all
      overflow-y-visible
      sm:max-w-full
      sm:py-7
    `,
    css`
      transition: all 500ms ease-in-out;
    `,
    showContent && tw`opacity-100`
  ]
);

export const SectionToneOfVoiceScaleLineContentContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      relative
      pt-2
    `}
`;

interface SectionToneOfVoiceScaleLineProps {
  inView: boolean;
}

export const SectionToneOfVoiceScaleLine = styled.div(
  ({ inView = false }: SectionToneOfVoiceScaleLineProps) => [
    tw`
      grid
      w-full
      justify-between
      h-0
    `,
    css`
      transition: height 750ms ease-in-out;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    `,
    inView && tw`h-8 sm:h-4`
  ]
);

interface SectionToneOfVoiceScaleItemContainerProps {
  backgroundColor?: string;
  selected?: boolean;
}

export const SectionToneOfVoiceScaleItemContainer = styled.div<SectionToneOfVoiceScaleItemContainerProps>(
  ({ backgroundColor = 'gray' }: SectionToneOfVoiceScaleItemContainerProps) => [
    css`
      transition: height 3.5s cubic-bezier(0.82, 0, 0.13, 1);
      background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
    `,
    tw`
    ml-0
    `,
    css`
      :first-child {
        ${tw`
          rounded-l-pillRadiusSliderDesktop
          sm:rounded-l-pillRadiusSliderMobile
        `}
      }
      :last-child {
        ${tw`
          rounded-r-pillRadiusSliderDesktop
          sm:rounded-r-pillRadiusSliderMobile
        `}
      }
    `
  ]
);

export const SectionToneOfVoiceScaleLineLabelContainer = styled.div`
  ${tw`
      flex
      w-full
      pt-2
      justify-between
    `}
`;

/* stylelint-disable selector-type-no-unknown */
export const SectionToneOfVoiceNavGradientContainer = styled.div`
  ${tw`sm:relative`}
  :before {
    ${tw`
      sm:content-none
      sm:absolute
      sm:left-0
      sm:w-12
      sm:z-10
      sm:top-2
      sm:-left-2
      sm:h-6
      sm:pointer-events-none
      sm:bg-gradient-l-transparent-to-gray2
    `}
  }

  :after {
    ${tw`
      sm:content-none
      sm:absolute
      sm:right-0
      sm:w-12
      sm:z-10
      sm:top-2
      sm:-right-2
      sm:h-6
      sm:pointer-events-none
      sm:bg-gradient-l-gray2-to-transparent
    `}
  }
`;

export const SectionToneOfVoiceNavContainer2 = styled.div`
  ${tw`
    relative
    flex
    items-center
    w-full
    overflow-scroll
    mb-9
    h-8
    sm:h-13
    sm:mb-7
    sm:w-auto
    sm:-mx-3
  `}
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionToneOfVoiceNavInsideContainer = styled.div`
  ${tw`
    absolute
    grid
    items-center
    justify-between
    grid-flow-col
    grid-cols-auto-fit/auto
    content-between
    sm:gap-7
    min-w-full
    sm:min-w-max-content
    sm:grid-cols-auto-fit/auto
    sm:px-5
  `}
`;

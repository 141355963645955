import React, { ReactElement } from 'react';

import {
  FORGOT_FORM_BACK_LABEL,
  FORGOT_SUCCESS_TITLE,
  FORGOT_SUCCESS_DESCRIPTION,
  FORGOT_SUCCESS_IMAGE
} from 'config';

import { Button as FormButton, BlockItem, Image } from 'component';

import { ForgotPasswordEmailFormButtonContainer } from './element';

interface Props {
  formBackLabel?: string;
  setTargetForm: (e: string) => void;
}

export const ForgotPasswordSuccess = ({
  formBackLabel = FORGOT_FORM_BACK_LABEL,
  setTargetForm
}: Props): ReactElement => (
  <>
    <Image src={FORGOT_SUCCESS_IMAGE} size="Med" />
    <BlockItem
      theme="BigHeadingText"
      captionScale="S"
      foregroundColor="black"
      heading={FORGOT_SUCCESS_TITLE}
      body={FORGOT_SUCCESS_DESCRIPTION}
      withLayout
    />
    <ForgotPasswordEmailFormButtonContainer>
      <FormButton size="Small" theme="PrimaryBlue" onClick={() => setTargetForm('MainLoginForm')}>
        {formBackLabel}
      </FormButton>
    </ForgotPasswordEmailFormButtonContainer>
  </>
);

export default ForgotPasswordSuccess;

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';

import SectionToneOfVoiceNav from './SectionToneOfVoiceNav';
import SectionToneOfVoiceScale from './SectionToneOfVoiceScale';

import { SectionToneOfVoiceModuleContainer } from './element';

export interface Block {
  heading: string;
  description: string;
  scaleIndex: number;
  navIndex?: number;
}

export interface Scale {
  label: {
    start: string,
    end: string
  };
  colors: string[];
}

interface Props {
  image: ImageType;
  scale: Scale;
  blocks?: Block[];
}

export const SectionToneOfVoiceModule = ({ image, scale, blocks = [] }: Props): ReactElement => {
  const [selectedItem, setSelectedItem] = useState(get(blocks, '0'));
  const [imageYPosition, setImageYPosition] = useState(24);
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    setShowContent(false);
    const timer = setTimeout(() => setShowContent(true), 500);
    return () => clearTimeout(timer);
  }, [imageYPosition]);

  const scaleContainer = useRef(null);

  const getScaleItemWidth = () =>
    get(scaleContainer, 'current.clientWidth') / get(scale, 'colors.length', 1);

  const calculateImageYPosition = (idx: number) =>
    getScaleItemWidth() * idx + (getScaleItemWidth() / 2 - 24);

  const selectHandler = (idx: number) => {
    setImageYPosition(calculateImageYPosition(blocks[idx].scaleIndex));
    setTimeout(
      () => setSelectedItem(blocks[idx] ? { ...blocks[idx], navIndex: idx } : get(blocks, '0')),
      500
    );
  };

  return (
    <SectionToneOfVoiceModuleContainer>
      <SectionToneOfVoiceNav
        blocks={blocks}
        selectHandler={selectHandler}
        selectedItem={selectedItem}
      />
      <SectionToneOfVoiceScale
        scale={scale}
        image={image}
        selectedItem={selectedItem}
        scaleContainer={scaleContainer}
        imageYPosition={imageYPosition}
        showContent={showContent}
      />
    </SectionToneOfVoiceModuleContainer>
  );
};

export default SectionToneOfVoiceModule;

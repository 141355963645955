import tw, { css, styled, TwStyle } from 'twin.macro';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getDesignSystemValue } = require('config/tailwind.config');

interface ResourcesListModuleContainerProps {
  backgroundColor?: string;
  show?: boolean;
}

export const ResourcesListModuleContainer = styled.div<ResourcesListModuleContainerProps>(
  ({ show = false }) => [
    tw`
    flex
    flex-col
    items-center
    bg-wazeBlue
    p-9
    font-boing
    border
    rounded-cardRadius
    w-full
    justify-between
    col-start-1
    col-end-3
    grid
    grid-flow-row
    col-start-1
    col-end-3
    md:grid
    md:grid-flow-row
    md:gap-3
    md:px-3
    md:py-4
    md:pr-7
    delay-700
    duration-1000
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0
  `,
    css`
      background-color: ${({
        backgroundColor = 'gray'
      }: ResourcesListModuleContainerProps): string =>
        getDesignSystemValue(`colors.${backgroundColor}`)};
    `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`
  ]
);

export const ResourcesListGroupContainer = styled.div`
  ${tw`
    mb-8
    w-full
    md:mb-4
  `}
`;

interface ResourcesListItemContainerProps {
  show?: boolean;
}

export const ResourcesListItemContainer =
  styled.div <
  ResourcesListItemContainerProps >
  `
  ${tw`
    grid
    col-gap-3
    row-gap-1
    justify-between
    w-full
    mb-4
    items-center
    grid-cols-3/auto
    sm:grid-cols-auto/1
    delay-700
    duration-500
    ease-in-out
    opacity-0
    transition-opacity
  `}

  grid-template-columns: 0fr 4fr 10fr;

  div {
    ${tw`
      w-full
    `}
  }

  ${({ show = false }: ResourcesListItemContainerProps): TwStyle =>
    show ? tw`opacity-100` : tw`opacity-0`}
`;

export const ResourcesListModuleMarkdownContainer = styled.div`
  ${tw`
    w-2/3
    sm:col-span-2
  `}
  div {
    ${tw`
      w-full
    `}
  }
`;

export const ResourcesListItemDownloadButton = styled.button`
  ${tw`
    flex
    items-center
    justify-center
    rounded-full
    border-2
    border-black
    h-12
    w-12
  `}
`;

interface HeadingContainerProps {
  show?: boolean;
}

export const HeadingContainer =
  styled.span <
  HeadingContainerProps >
  `
  h3,
  h5 {
    ${tw`
    delay-500
    duration-700
    ease-in-out
    transition-opacity`};
    opacity: ${({ show }: HeadingContainerProps): string => (show ? `1` : `0`)};
  }

  h3:nth-child(1) {
    ${tw`
      mb-4
    `}
  }

  h5:nth-child(2) {
    ${tw`
      mb-4
    `}
  }
`;

export const ResourcesDownloadContainer = styled.a.attrs(props => ({
  target: props.target || "_self",
}))`
`;

import tw, { styled } from 'twin.macro';

export const GoogleSignInButtonContainer = styled.div`
  ${tw`flex items-center`}
  svg {
    ${tw`mr-1`}
  }
`;

export const ButtonsContainer = styled.div`
  ${tw`
    flex
    flex-col
    items-center
    content-center
    z-10
  `}

  a {
    ${tw`mb-2 w-80`}
  }

  @media not all and (min-resolution: 0.001dpcm) {
    ${tw`
        mt-16
        w-80
      `}

    a {
      ${tw`w-80`}
    }
  }
`;

export const FootContainer = styled.div`
  ${tw`z-10     flex
    flex-col
    items-center
    content-center
  `}

  div {
    ${tw`text-center`}
  }
`;

export const MainLoginFormContainer = styled.div`
  z-index: 100000;
  @media not all and (min-resolution: 0.001dpcm) {
    ${tw`
        items-center
        flex
        flex-col
        relative
        mb-20
      `};
  }
`;

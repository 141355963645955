import tw, { styled } from 'twin.macro';

interface BlockTwoUpContainerProps {
  sidenav: boolean;
  show?: boolean;
}

export const BlockTwoUpContainer = styled.div(({ sidenav, show }: BlockTwoUpContainerProps) => [
  tw`
    w-full
    md:col-auto
    delay-700
    duration-500
    ease-in-out
    opacity-0
    transition-opacity
    `,
  show && tw`opacity-100`,
  sidenav
    ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
    : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`
]);

import React, { ReactElement, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { get } from 'lodash';

import Image from 'component/Image/Image';
import BlockItem from 'component/BlockItem/BlockItem';
import Reaction from 'component/Reaction/Reaction';

import FeedbackForm from 'form/FeedbackForm';
import FormState from 'container/FormState';

import {
  FeedbackModuleContainer,
  FeedbackModuleContent,
  FeedbackModuleBlockContainer,
  FeedbackModuleFormContainer,
  ImageContainer
} from './element';

interface DropdownOption {
  name: string;
  value: string;
}

interface FeedbackModuleConfirmation {
  image: ImageType;
  foregroundColor: string;
  backgroundColor: string;
  heading: string;
  description: string;
}

interface FeedbackModuleProps {
  sidenav?: boolean;
  backgroundColor?: string;
  heading?: string;
  description?: string;
  formLabel?: string;
  dropdownPlaceholder?: string;
  formPlaceholder?: string;
  wazers?: Wazer[];
  dropdownOptions: DropdownOption[];
  confirmation: FeedbackModuleConfirmation;
}

export const FeedbackModule = ({
  sidenav = false,
  backgroundColor = 'gray2',
  heading,
  description,
  formLabel,
  dropdownPlaceholder,
  formPlaceholder,
  wazers,
  dropdownOptions,
  confirmation
}: FeedbackModuleProps): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });
  const [feedbackSentSuccess, setFeedbackSentSuccess] = useState(false);

  return feedbackSentSuccess ? (
    <Reaction
      image={confirmation.image.path}
      imageSize={confirmation.image.size || 'XL'}
      description={confirmation.description}
      heading={confirmation.heading}
      foregroundColor={confirmation.foregroundColor}
      backgroundColor={confirmation.backgroundColor}
    />
  ) : (
    <FormState.Provider>
      <FeedbackModuleContainer
        sidenav={sidenav}
        backgroundColor={backgroundColor}
        ref={ref}
        show={inView}>
        <FeedbackModuleContent>
          <FeedbackModuleBlockContainer>
            <BlockItem
              theme="BigHeadingText"
              heading={heading}
              body={description}
              align="left"
              withoutContainer
            />
          </FeedbackModuleBlockContainer>
          <FeedbackModuleFormContainer>
            <FeedbackForm
              onSuccess={() => {
                setFeedbackSentSuccess(true);
              }}
              formLabel={formLabel}
              dropdownPlaceholder={dropdownPlaceholder}
              dropdownOptions={dropdownOptions}
              formPlaceholder={formPlaceholder}
            />
            <ImageContainer>
              <Image src={get(wazers, '0.image.path')} size="XS" />
            </ImageContainer>
          </FeedbackModuleFormContainer>
        </FeedbackModuleContent>
      </FeedbackModuleContainer>
    </FormState.Provider>
  );
};

export default FeedbackModule;

import tw, { styled, css } from 'twin.macro';

interface BlockFullWithAccentsModuleContainerProps {
  sidenav: boolean;
  login: boolean;
  show?: boolean;
  idx?: number;
}

export const BlockFullWithAccentsModuleContainer = styled.div(
  ({ sidenav, show = false, login }: BlockFullWithAccentsModuleContainerProps) => [
    tw`
      relative
      w-full
      md:col-auto
      delay-700
      duration-700
      ease-in-out
      opacity-0
      transition-all
      translate-y-4
      transform
    `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`,

    sidenav
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    login && show && tw`col-start-1 col-end-1 md:col-start-1 md:col-end-3`
  ]
);

interface WazerContainerProps {
  direction: 'left' | 'right';
  position?: string;
}

export const WazerContainer = styled.div`
  ${tw`
    absolute
    bottom-0
    -mb-3
    z-10
  `}
  ${({ direction, position }: WazerContainerProps) => [
    direction === 'left' ? tw`ml-5` : tw`mr-5`,
    direction === 'left'
      ? css`
          transform: scaleX(-1);
        `
      : '',
    position
      ? css`
          ${direction === 'left' ? `left: ${position};` : `right: ${position};`};
        `
      : direction === 'left'
      ? tw`left-0`
      : tw`right-0`
  ]}
`;

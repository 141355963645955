import { reduce, get, findKey } from 'lodash';
import { foregroundConfig } from './config/design';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require('config/tailwind.config');

const {
  theme: { colors }
} = config;

export const mathOperationForStyleValues = (value = '0', operator: string, by: number): string => {
  const integerValue = parseInt(value, 10);
  if (integerValue === 0) {
    return '0px';
  }
  const unit = value.match(/[a-z]+/g) || ['rem'];
  if (operator === '/') {
    return `${parseInt(value, 10) / by}${unit[0]}`;
  }
  return `${parseInt(value, 10) * by}${unit[0]}`;
};

export const getColor = (name: string): string => get(colors, name, 'black');

/* eslint-disable max-statements */
const createBlock = () => {
  const block: Block & {
    initialize: (data: Block) => void
  } = {
    accent: '',
    animation: undefined,
    theme: 'HeadingTextButton',
    columns: '12',
    backgroundColor: 'gray',
    foregroundColor: '',
    captionScale: undefined,
    image: undefined,
    header: '',
    body: '',
    buttons: [],
    wazer: undefined,
    align: undefined,
    leftDecorator: undefined,
    rightDecorator: undefined,
    initialize: (data: Block): void => {
      block.accent = get(data, 'accent', block.accent);
      block.theme = get(data, 'theme', block.theme);
      block.columns = get(data, 'columns', block.columns);
      block.backgroundColor = get(data, 'backgroundColor', block.backgroundColor);
      block.foregroundColor = findKey(
        foregroundConfig,
        (colors: string[]): boolean => colors.indexOf(data.backgroundColor || 'gray') >= 0
      );
      block.captionScale = get(data, 'captionScale', block.captionScale);
      block.image = get(data, 'image', block.image);
      block.width = get(data, 'width', block.width);
      block.header = get(data, 'header', block.header);
      block.body = get(data, 'body', block.body);
      block.buttons = get(data, 'buttons', block.buttons);
      block.wazer = get(data, 'wazer', block.wazer);
      block.animation = get(data, 'animation', block.animation);
      block.align = get(data, 'align', block.align);
      block.leftDecorator = get(data, 'leftDecorator', block.leftDecorator);
      block.rightDecorator = get(data, 'rightDecorator', block.rightDecorator);
    }
  };
  return block;
};

export const createBlocks = (count: number, data?: Block[] | undefined): Block[] => {
  const array = new Array(count);
  return reduce(
    array,
    (nextBlocks: Block[], b: undefined, index: number) => {
      const block = createBlock();
      if (data && data[index]) {
        block.initialize(data[index]);
      }
      return [...nextBlocks, block];
    },
    []
  );
};

export const generateColumnsName = (blocks: Block[]): string =>
  reduce(
    blocks,
    (nextColumns: string, block: Block, index: number) => {
      if (index === 0) {
        return block.columns || '6';
      }
      return `${nextColumns || '6'}/${block.columns || '6'}`;
    },
    '6/6'
  );

import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import Image from 'component/Image';

import { Container, LinkContainer, StyledLink } from './element';

import ArrowLeft from '../../../static/image/icons/ArrowLeft.svg';
import ArrowRight from '../../../static/image/icons/ArrowRight.svg';

type LinkType = {
  name: string,
  link: string
};

interface PrevNextHandlingModuleProps {
  sidenav: boolean;
  pagination: {
    prev?: LinkType,
    next?: LinkType
  };
}

export const PrevNextHandlingModule = ({
  sidenav,
  pagination: { prev, next }
}: PrevNextHandlingModuleProps): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '400px 0px', triggerOnce: true });

  return (
    <Container hasPrev={!!prev} hasNext={!!next} sidenav={sidenav} ref={ref} show={inView}>
      {prev && (
        <StyledLink to={prev.link} direction="left">
          <LinkContainer>
            <Image src={ArrowLeft} width="48" height="48" />
          </LinkContainer>
          {prev.name}
        </StyledLink>
      )}
      {next && (
        <StyledLink to={next.link} direction="right">
          {next.name}
          <LinkContainer>
            <Image src={ArrowRight} width="48" height="48" />
          </LinkContainer>
        </StyledLink>
      )}
    </Container>
  );
};

export default PrevNextHandlingModule;

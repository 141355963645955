import React, { cloneElement, isValidElement, FunctionComponent, ReactElement } from 'react';
import { compact, get, isEmpty } from 'lodash';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';

import ErrorMessage from 'component/ErrorMessage';
import FormState from 'container/FormState';

interface Props {
  children: ReactElement;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
}

export const Form: FunctionComponent<Props> = ({ children, errors, touched, ...props }: Props) => {
  const { errorMessage } = FormState.useContainer();

  const validationErrors = Object.keys(Object(errors))
    ? compact(
        Object.keys(Object(errors))
          .slice()
          .map((e: string) => {
            if (get(touched, e)) {
              return get(errors, e);
            } else {
              return null;
            }
          })
      )
    : [];

  const error = !isEmpty(validationErrors) ? validationErrors : errorMessage;

  return (
    <FormState.Provider>
      {/* @ts-ignore */}
      {!isEmpty(error) && <ErrorMessage>{error}</ErrorMessage>}
      {isValidElement(children) && cloneElement(children, props)}
    </FormState.Provider>
  );
};

export default Form;

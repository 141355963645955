/* eslint-disable max-lines */
import React, { ReactElement, useContext } from 'react';
import { graphql } from 'gatsby';

import { PageMetadata } from '../component';
import { renderTargetModule } from '../lib/contentModule';
import { ModuleContainer } from './element';
import AuthContext from '../AuthContext';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  data: {
    content: {
      modules: any[],
      meta: {
        page: any
      },
      login?: boolean
    }
  };
}

export const PageTemplate = ({
  data: {
    content: { modules = [], ...contentProps }
  }
}: Props): ReactElement => {
  const {
    meta: { page }
  } = contentProps;

  const { permissionLevel } = useContext(AuthContext);

  return (
    <ModuleContainer login={contentProps.login}>
      <PageMetadata {...page} />
      {modules.map((module: any, idx: number) =>
        renderTargetModule(module, idx, contentProps, permissionLevel)
      )}
    </ModuleContainer>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query pageQuery($name: String!) {
    content(meta: { page: { name: { eq: $name } } }) {
      meta {
        page {
          ... on ContentMetaPage {
            name
            title
            slug
          }
        }
      }
      sidenav
      login
      modules {
        ... on ContentModules {
          __typename
          sectionId
          type
          disallowRoles
          disableOnMobile
          allowPublic
          orientation
          singleColumn
          heading
          description
          backgroundColor
          dropdownPlaceholder
          dropdownOptions {
            name
          }
          confirmation {
            image {
              path
              size
            }
            foregroundColor
            backgroundColor
            heading
            description
          }
          formLabel
          formPlaceholder
          leftDecorator {
            type
            enabled
            color
          }
          rightDecorator {
            type
            enabled
            color
          }
          wazer {
            animate
            direction
            image
            position
            width
          }
          wazers {
            image {
              path
            }
          }
          pagination {
            ... on ContentModulesPagination {
              prev {
                link
                name
              }
              next {
                link
                name
              }
            }
          }
          options {
            ... on ContentModulesOptions {
              name
              link
              disallowRoles
              allowPublic
              children {
                name
                link
                disallowRoles
              }
            }
          }
          tags {
            ... on ContentModulesTags {
              displayName
              name
            }
          }
          showCaseItems {
            ... on ContentModulesShowCaseItems {
              title
              image {
                path
              }
              audio {
                path
              }
              video {
                path
              }
              tag
            }
          }
          listItems {
            ... on ContentModulesListItems {
              groupHeading
              disallowRoles
              allowPublic
              items {
                heading
                description
                link
                disallowRoles
                allowPublic
              }
            }
          }
          summaryItems {
            ... on ContentModulesSummaryItems {
              description
              heading
              image {
                path
              }
              button {
                ... on ContentModulesSummaryItemsButton {
                  type
                  text
                  link
                  theme
                  size
                }
              }
            }
          }
          sections {
            ... on ContentModulesSections {
              type
              form
              foot
              columns
              width
              theme
              header
              backgroundColor
              body

              image {
                path
                size
              }
              images {
                path
              }
              heading
              description
              orientation
              aspectRatio
              captionScale
              scale {
                ... on ContentModulesSectionsScale {
                  label {
                    start
                    end
                  }
                  colors
                }
              }
              leftDecorator {
                type
                enabled
                color
              }
              rightDecorator {
                type
                enabled
                color
              }
              wazer {
                animate
                direction
                image
                width
              }
              animation {
                name
                size
              }
              blocks {
                ... on ContentModulesSectionsBlocks {
                  heading
                  description
                  scaleIndex
                }
              }
              buttons {
                ... on ContentModulesSectionsButtons {
                  type
                  text
                  link
                  theme
                  size
                  download
                  target
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;

import * as Yup from 'yup';

const EMAIL_INVALID_ERROR_MESSAGE = `Oops. Please enter a valid email address.`;
const EMAIL_REQUIRED_ERROR_MESSAGE = `Please enter your email address.`;

export const ForgotPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email(EMAIL_INVALID_ERROR_MESSAGE).required(EMAIL_REQUIRED_ERROR_MESSAGE)
});

export default ForgotPasswordFormSchema;

import React from 'react';
import { useInView } from 'react-intersection-observer';

import VerticalMenu from 'component/VerticalMenu';

import { SideNavModuleContainer, SideNavModuleContent } from './element';

interface SideNavModuleOptions {
  name: string;
  link?: string;
  children?: SideNavModuleOptions[];
}

interface PropsType {
  options: SideNavModuleOptions[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SideNavModule = ({ options, ...props }: PropsType) => {
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <SideNavModuleContainer ref={ref} show={inView}>
      <SideNavModuleContent>
        <VerticalMenu {...props} options={options} theme="A"></VerticalMenu>
      </SideNavModuleContent>
    </SideNavModuleContainer>
  );
};

export default SideNavModule;

import React, { ReactElement } from 'react';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { Image, Paragraph } from 'component';

import {
  ShowcaseCloseButton,
  ShowcaseModuleModalCloseButtonContainer,
  ShowCaseModuleItemContainer
} from './element';

const CLOSE_MODAL_BUTTON_TEXT = `Back to Showcase`;

/* eslint-disable max-lines-per-function */
export const ShowCaseModuleItem = ({
  image,
  audio,
  video,
  title,
  toggleModal,
  modalIsOpen,
  idx
}: {
  image: ImageType,
  audio?: AudioType,
  video?: VideoType,
  title: string,
  toggleModal: (props: {
    [k: string]: string | ImageType | AudioType | VideoType | undefined
  }) => void,
  modalIsOpen?: boolean,
  idx?: number
}): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '650px 0px', triggerOnce: true });

  const getMediaType = (media: string) => {
    const path = get(media === 'video' ? video : audio, 'path', '');
    console.log('path', path);
    const type = path.split('.')[1];

    return {
      type: `${media}/${type}`
    };
  };

  const toggleModalListener = (e: React.MouseEvent<Element, MouseEvent>) => {
    /* eslint-disable-next-line */
    const element = e.target as HTMLElement;
    if (element && element.tagName.toLowerCase() === 'a') {
      const linkElement = element as HTMLLinkElement;
      e.stopPropagation();
      e.preventDefault();
      window.open(linkElement.href, '_blank', 'noopener');
      return;
    } else {
      !modalIsOpen && toggleModal({ image, audio, video, title });
    }
  };

  return (
    <ShowCaseModuleItemContainer
      ref={ref}
      modalIsOpen={modalIsOpen}
      show={inView}
      idx={idx}
      isEmpty={!title && !image.path}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => toggleModalListener(e)}>
      {(!video || (video && !modalIsOpen)) && <Image src={get(image, 'path', '')} />}
      {video && modalIsOpen && (
        <video controls>
          <source src={get(video, 'path', '')} {...getMediaType('video')} />
          <p>
            Your browser doesn{"'"}t support HTML5 video. Here is a
            <a href="myVideo.mp4">link to the video</a> instead.
          </p>
        </video>
      )}
      <Paragraph markdownText={title} textStyle={{ marginTop: '3' }} />
      {modalIsOpen && (
        <>
          <ShowcaseModuleModalCloseButtonContainer>
            <ShowcaseCloseButton onClick={() => toggleModal({ image, audio, video, title })}>
              {CLOSE_MODAL_BUTTON_TEXT}
            </ShowcaseCloseButton>
          </ShowcaseModuleModalCloseButtonContainer>
          {audio && (
            <audio controls src={get(audio, 'path', '')} {...getMediaType('audio')}>
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          )}
        </>
      )}
    </ShowCaseModuleItemContainer>
  );
};

export default ShowCaseModuleItem;

import React, { ReactElement } from 'react';
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';

import Button from 'component/Button/Button';
import InputTextField from 'component/InputTextField/InputTextField';

import Form from 'form/Form';

import { SignupFormButtonContainer } from './element';

interface Props {
  formSubmitLabel: string;
  formBackLabel: string;
  submitForm: () => Promise<void>;
  setTargetForm: (e: string) => void;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
}

const Buttons = ({
  formSubmitLabel,
  formBackLabel,
  submitForm,
  setTargetForm
}: {
  formSubmitLabel: string,
  formBackLabel: string,
  submitForm: () => Promise<void>,
  setTargetForm: (e: string) => void
}): ReactElement => (
  <SignupFormButtonContainer>
    <Button size="Small" onClick={submitForm}>
      {formSubmitLabel}
    </Button>
    <Button size="Small" theme="PrimaryWhite" onClick={() => setTargetForm('MainLoginForm')}>
      {formBackLabel}
    </Button>
  </SignupFormButtonContainer>
);

const Fields = () => (
  <>
    <Field
      aria-label="Email"
      autoComplete="email"
      spellCheck={false}
      component={InputTextField}
      name="email"
      placeholder={'Email'}
      type="email"
    />
    <Field
      aria-label="Password"
      spellCheck={false}
      component={InputTextField}
      name="password"
      placeholder={'Password'}
      type="password"
    />
  </>
);

export const SignUpForm = ({
  formSubmitLabel,
  formBackLabel,
  submitForm,
  setTargetForm,
  errors,
  touched
}: Props): ReactElement => (
  <Form errors={errors} touched={touched}>
    <>
      <Fields />
      <Buttons
        formSubmitLabel={formSubmitLabel}
        formBackLabel={formBackLabel}
        submitForm={submitForm}
        setTargetForm={setTargetForm}
      />
    </>
  </Form>
);

export default SignUpForm;

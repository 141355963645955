import React, { ReactElement, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Heading, MarkdownText } from 'component';

import DownloadArrow from 'illustration/DownloadArrow';
import { isRoleDisallowed } from 'lib/role';

import {
  HeadingContainer,
  ResourcesDownloadContainer,
  ResourcesListModuleContainer,
  ResourcesListItemContainer,
  ResourcesListGroupContainer,
  ResourcesListModuleMarkdownContainer,
  ResourcesListItemDownloadButton
} from './element';

interface Item {
  description: string;
  heading: string;
  link?: string;
}
interface ListItem {
  items: Item[];
  groupHeading?: string;
}

interface Props {
  backgroundColor?: string;
  listItems?: ListItem[];
  currentRole?: string;
}

const ResourceListItem = ({ description, heading, link }: Item) => {
  const [isHovered, setIsHovered] = useState(false);
  const [ref, inView] = useInView({ rootMargin: '300px 0px', triggerOnce: true });
  let target = '_self'
  if (link?.startsWith('http')) {
    target = '_blank'
  }
  return (
    <ResourcesDownloadContainer href={link} target={target}>
      <ResourcesListItemContainer ref={ref} show={inView}>
        <ResourcesListItemDownloadButton
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <DownloadArrow toggleAnimation={isHovered} />
        </ResourcesListItemDownloadButton>
        <Heading headingScaleName="H5">{heading}</Heading>
        <ResourcesListModuleMarkdownContainer>
          <MarkdownText markdownText={description} />
        </ResourcesListModuleMarkdownContainer>
      </ResourcesListItemContainer>
    </ResourcesDownloadContainer>
  );
};

const HeadingGroup = ({ groupHeading }: { groupHeading: string }) => {
  const [ref, inView] = useInView({ rootMargin: '300px 0px', triggerOnce: true });

  return (
    <HeadingContainer ref={ref} show={inView}>
      <Heading headingScaleName="H4">{groupHeading}</Heading>
    </HeadingContainer>
  );
};

const ResourcesListGroup = ({
  listItem,
  currentRole,
  idx
}: {
  listItem: ListItem,
  currentRole?: string,
  idx: number
}): ReactElement => (
  <ResourcesListGroupContainer key={`ResourcesListGroup-${idx}`}>
    {listItem.groupHeading && <HeadingGroup groupHeading={listItem.groupHeading} />}
    {listItem.items.map(
      (item: Item, idx: number) =>
        !isRoleDisallowed({ currentRole, module: item }) && (
          <ResourceListItem key={`ResourceListItem-${idx}`} {...item} />
        )
    )}
  </ResourcesListGroupContainer>
);

export const ResourcesListModule = ({
  backgroundColor = 'gray2',
  listItems = [],
  currentRole
}: Props): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '250px 0px', triggerOnce: true });

  return (
    <ResourcesListModuleContainer backgroundColor={backgroundColor} ref={ref} show={inView}>
      {listItems.map(
        (listItem: ListItem, lidx: number) =>
          !isRoleDisallowed({ currentRole, module: listItem }) && (
            <ResourcesListGroup
              listItem={listItem}
              idx={lidx}
              key={`ResourcesListGroup-${lidx}`}
              currentRole={currentRole}
            />
          )
      )}
    </ResourcesListModuleContainer>
  );
};

export default ResourcesListModule;

import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import { Block } from './SectionToneOfVoiceModule';

import {
  SectionToneOfVoiceNavGradientContainer,
  SectionToneOfVoiceNavItemContentContainer,
  SectionToneOfVoiceNavContainer2,
  SectionToneOfVoiceNavItemContainer,
  SectionToneOfVoiceNavInsideContainer
} from './element';

export const SectionToneOfVoiceNavItem = ({
  label,
  selectHandler,
  idx,
  selectedItem,
  inView
}: {
  label: string,
  selectHandler: (idx: number) => void,
  idx: number,
  selectedItem: Block,
  inView: boolean
}): ReactElement => (
  <SectionToneOfVoiceNavItemContentContainer>
    <SectionToneOfVoiceNavItemContainer
      inView={inView}
      delay={(idx + 1) * 300}
      onClick={() => selectHandler(idx)}
      selected={selectedItem?.navIndex === idx}>
      {label}
    </SectionToneOfVoiceNavItemContainer>
  </SectionToneOfVoiceNavItemContentContainer>
);

interface Props {
  blocks: Block[];
  selectHandler: (idx: number) => void;
  selectedItem: Block;
}
export const SectionToneOfVoiceNav = ({
  blocks = [],
  selectHandler,
  selectedItem
}: Props): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <>
      <SectionToneOfVoiceNavGradientContainer />
      <SectionToneOfVoiceNavContainer2 ref={ref}>
        <SectionToneOfVoiceNavInsideContainer>
          {blocks.map(({ heading }: Block, idx: number) => (
            <SectionToneOfVoiceNavItem
              label={heading}
              selectHandler={selectHandler}
              key={`SectionToneOfVoiceNavItem-${idx}`}
              idx={idx}
              selectedItem={selectedItem}
              inView={inView}
            />
          ))}
        </SectionToneOfVoiceNavInsideContainer>
      </SectionToneOfVoiceNavContainer2>
    </>
  );
};

export default SectionToneOfVoiceNav;

import tw, { styled } from 'twin.macro';

interface ModuleContainerProps {
  login?: boolean;
}

export const ModuleContainer = styled.div(({ login = false }: ModuleContainerProps) => [
  tw`
    grid
    grid-flow-row
    gap-3
    sm:gap-2
    w-full
    grid-cols-sitewide
    pt-12
    sm:pt-7
  `,
  login && tw`grid-cols-60/40 grid-rows-1fr sm:grid-cols-full sm:grid-rows-auto`
]);

import * as Yup from 'yup';
import { passwordStrength } from 'check-password-strength';

const LOGIN_WITH_EMAIL_EMAIL_INVALID_ERROR_MESSAGE = `Oops. Please enter a valid email address.`;
const LOGIN_WITH_EMAIL_EMAIL_REQUIRED_ERROR_MESSAGE = `Please enter your email address.`;
const LOGIN_WITH_EMAIL_PASSWORD_REQUIRED_ERROR_MESSAGE = `Please entere your password.`;
const LOGIN_WITH_EMAIL_PASSWORD_MIN_LENGTH_ERROR_MESSAGE = `Please enter a password at least 6 characters long.`;
const LOGIN_WITH_EMAIL_PASSWORD_MAX_LENGTH_ERROR_MESSAGE = `Please enter a password no more than 20 characters long.`;
const LOGIN_WITH_EMAIL_PASSWORD_STRENGTH_ERROR_MESSAGE = `Almost there! Please use at least one uppercase and one number in your password`;

export const SignupWithEmailFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(LOGIN_WITH_EMAIL_EMAIL_INVALID_ERROR_MESSAGE)
    .required(LOGIN_WITH_EMAIL_EMAIL_REQUIRED_ERROR_MESSAGE),
  password: Yup.string()
    .test('passwordStrengthCheck', LOGIN_WITH_EMAIL_PASSWORD_STRENGTH_ERROR_MESSAGE, (passwd) => {
      return ['Medium', 'Strong'].includes(passwordStrength(passwd).value);
    })
    .min(6, LOGIN_WITH_EMAIL_PASSWORD_MIN_LENGTH_ERROR_MESSAGE)
    .max(20, LOGIN_WITH_EMAIL_PASSWORD_MAX_LENGTH_ERROR_MESSAGE)
    .required(LOGIN_WITH_EMAIL_PASSWORD_REQUIRED_ERROR_MESSAGE)
});

export default SignupWithEmailFormSchema;

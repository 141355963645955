import tw, { css, styled } from 'twin.macro';

interface TwoUpNxNDiagonalBlock {
  columns?: string;
  show?: boolean;
  idx?: number;
  shrinkHeight?: boolean;
}

export const Container = styled.div`
  ${tw`
    grid
    grid-flow-col
    grid-cols-12
    gap-3
    w-full
    overflow-hidden
    rounded-cardRadius
    col-start-1
    col-end-13
    sm:grid-rows-2/auto
    sm:grid-flow-row
    sm:grid-cols-none
    sm:col-start-auto
    sm:col-end-auto
    sm:gap-2
  `};
`;

const blockGridColumns = {
  '1': tw`col-span-1 sm:col-auto`,
  '2': tw`col-span-2 sm:col-auto`,
  '3': tw`col-span-3 sm:col-auto`,
  '4': tw`col-span-4 sm:col-auto`,
  '5': tw`col-span-5 sm:col-auto`,
  '6': tw`col-span-6 sm:col-auto`,
  '7': tw`col-span-7 sm:col-auto`,
  '8': tw`col-span-8 sm:col-auto`,
  '9': tw`col-span-9 sm:col-auto`,
  '10': tw`col-span-10 sm:col-auto`,
  '11': tw`col-span-11 sm:col-auto`,
  '12': tw`col-span-12 sm:col-auto`
};

export const Block = styled.div(({ columns, show, idx, shrinkHeight }: TwoUpNxNDiagonalBlock) => [
  blockGridColumns[columns || '6'],
  tw`
    duration-700
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0
  `,
  shrinkHeight &&
    tw`h-24
    max-h-24`,
  show ? tw`opacity-100` : tw`opacity-0`,
  show ? tw`translate-y-0` : tw`translate-y-4`,
  css`
    transition-delay: ${idx ? (idx + 1) * 375 : 375}ms;
  `
]);

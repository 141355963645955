import React from 'react';

import { StyledErrorMessage } from './element';

export const ErrorMessage: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({
  ...restProps
}) => {
  return <StyledErrorMessage {...restProps} />;
};

export default ErrorMessage;

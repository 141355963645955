import React, { ReactElement, RefObject } from 'react';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { Heading, Image, Paragraph } from 'component';

import { Block, Scale } from './SectionToneOfVoiceModule';

import {
  SectionToneOfVoiceScaleContainer,
  SectionToneOfVoiceScaleItemContainer,
  SectionToneOfVoiceScaleLine,
  SectionToneOfVoiceScaleWazer,
  SectionToneOfVoiceScaleDescription,
  SectionToneOfVoiceScaleLineContentContainer,
  SectionToneOfVoiceScaleLineLabelContainer
} from './element';

interface Props {
  scale: Scale;
  image: ImageType;
  selectedItem?: Block;
  scaleContainer: RefObject<HTMLDivElement>;
  imageYPosition: number;
  showContent: boolean;
}

export const SectionToneOfVoiceScaleItem = ({
  color,
  idx,
  selectedItem
}: {
  color: string,
  idx: number,
  selectedItem?: Block
}): ReactElement => (
  <SectionToneOfVoiceScaleItemContainer
    backgroundColor={color}
    selected={selectedItem?.scaleIndex === idx}
  />
);

export const SectionToneOfVoiceScale = ({
  scale,
  image,
  selectedItem,
  scaleContainer,
  imageYPosition,
  showContent
}: Props): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <SectionToneOfVoiceScaleContainer>
      <SectionToneOfVoiceScaleLineContentContainer ref={scaleContainer}>
        <SectionToneOfVoiceScaleLine ref={ref} inView={inView}>
          {get(scale, 'colors').map((color: string, idx: number) => (
            <SectionToneOfVoiceScaleItem
              color={color}
              key={`SectionToneOfVoiceScaleItem-${idx}`}
              idx={idx}
              selectedItem={selectedItem}
            />
          ))}
        </SectionToneOfVoiceScaleLine>
        <SectionToneOfVoiceScaleWazer imageYPosition={imageYPosition} inView={inView}>
          <Image src={get(image, 'path')} size={get(image, 'size')} />
        </SectionToneOfVoiceScaleWazer>
      </SectionToneOfVoiceScaleLineContentContainer>
      <SectionToneOfVoiceScaleLineLabelContainer>
        <Heading headingScaleName="H6">{get(scale, 'label.start')}</Heading>
        <Heading headingScaleName="H6">{get(scale, 'label.end')}</Heading>
      </SectionToneOfVoiceScaleLineLabelContainer>
      <SectionToneOfVoiceScaleDescription showContent={showContent}>
        <Paragraph
          captionScaleName="M"
          markdownText={selectedItem?.description}
          textStyle={{ textAlign: 'center' }}
          textStyleForMobile={{ textAlign: 'center' }}
        />
      </SectionToneOfVoiceScaleDescription>
    </SectionToneOfVoiceScaleContainer>
  );
};

export default SectionToneOfVoiceScale;

import React, { ReactElement } from 'react';
import { findKey } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { foregroundConfig } from 'config/design';

import BlockItem from 'component/BlockItem/BlockItem';

import { SectionTextOnlyModuleContainer, SectionTextOnlyModuleTextContainer } from './element';

export type BlockType = {
  heading: string,
  description: string
};

export interface Props {
  backgroundColor?: string;
  orientation?: OrientationPropType;
  aspectRatio?: AspectRatioType;
  captionScale?: CaptionScaleNameType;
  blocks: BlockType[];
}

export const SectionTextOnlyModule = ({
  backgroundColor = 'gray',
  orientation,
  aspectRatio,
  captionScale,
  blocks
}: Props): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <SectionTextOnlyModuleContainer aspectRatio={aspectRatio} ref={ref} show={inView}>
      {blocks && blocks.length > 0
        ? blocks.map((block: BlockType, index: number) => (
            <SectionTextOnlyModuleTextContainer
              key={`block_${index}`}
              index={index}
              orientation={orientation}>
              <BlockItem
                captionScale={captionScale}
                foregroundColor={findKey(
                  foregroundConfig,
                  (colors: string[]): boolean => colors.indexOf(backgroundColor) >= 0
                )}
                heading={block.heading}
                body={block.description}
                align="left"
                withoutContainer
              />
            </SectionTextOnlyModuleTextContainer>
          ))
        : null}
    </SectionTextOnlyModuleContainer>
  );
};

export default SectionTextOnlyModule;

import React, { ReactElement, useState } from 'react';

import {
  FORGOT_FORM_TITLE,
  FORGOT_FORM_DESCRIPTION_MARKDOWN,
  FORGOT_FORM_SUBMIT_LABEL,
  FORGOT_FORM_BACK_LABEL,
  FORGOT_ERROR_MESSAGE
} from 'config';

import BlockItem from 'component/BlockItem/BlockItem';

import FormState, { FormStateType } from 'container/FormState';
import { auth } from 'lib/firebase';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ForgotPasswordSuccess } from './ForgotPasswordSuccess';
import { ForgotPasswordEmailFormContainer } from './element';

interface Props {
  formTitle?: string;
  formDescriptionMarkdown?: string;
  formSubmitLabel?: string;
  formState?: FormStateType;
  formBackLabel?: string;
  setTargetForm: (formName: string) => void;
}

const onSubmit = async ({
  email,
  formState,
  successHandler
}: {
  email: string,
  formState: FormStateType,
  successHandler: () => void
}) => {
  const { setErrorMessage } = formState;

  try {
    await auth.sendPasswordResetEmail(email);
    successHandler();
  } catch (error) {
    setErrorMessage(FORGOT_ERROR_MESSAGE);
  }
};

export const ForgotPasswordEmailForm = ({
  formTitle = FORGOT_FORM_TITLE,
  formDescriptionMarkdown = FORGOT_FORM_DESCRIPTION_MARKDOWN,
  formSubmitLabel = FORGOT_FORM_SUBMIT_LABEL,
  formBackLabel = FORGOT_FORM_BACK_LABEL,
  setTargetForm
}: Props): ReactElement => {
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const formState = FormState.useContainer();

  return (
    <ForgotPasswordEmailFormContainer>
      {forgotSuccess ? (
        <ForgotPasswordSuccess setTargetForm={setTargetForm} />
      ) : (
        <>
          <BlockItem
            theme="BigHeadingText"
            captionScale="S"
            foregroundColor="black"
            heading={formTitle}
            body={formDescriptionMarkdown}
            withLayout
          />
          <ForgotPasswordForm
            onSubmit={onSubmit}
            formState={formState}
            setForgotSuccess={setForgotSuccess}
            setTargetForm={setTargetForm}
            formSubmitLabel={formSubmitLabel}
            formBackLabel={formBackLabel}
          />
        </>
      )}
    </ForgotPasswordEmailFormContainer>
  );
};

export default ForgotPasswordEmailForm;

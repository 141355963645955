import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { get } from 'lodash';

import { BlockFullWithAccentsModuleContainer, WazerContainer } from './element';

import BlockItem from 'component/BlockItem/BlockItem';
import BlockWithDecorators from 'component/BlockWithDecorators/BlockWithDecorators';
import Image from 'component/Image/Image';
import Wazer from 'component/Wazer/Wazer';

import { createBlocks } from 'utils';

interface BlockFullWithAccentsModuleProps {
  sidenav: boolean;
  login?: boolean;
  sections?: Block[];
  leftDecorator?: DecoratorType;
  rightDecorator?: DecoratorType;
  backgroundColor?: string;
  orientation?: 'right' | 'left';
  wazer?: Wazer;
}

export const BlockFullWithAccentsModule = ({
  sidenav,
  login = false,
  sections,
  leftDecorator = {
    type: 'rectangle'
  },
  rightDecorator = {
    type: 'rectangle'
  },
  backgroundColor,
  wazer
}: BlockFullWithAccentsModuleProps): ReactElement => {
  const blocks = createBlocks(1, sections);

  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <BlockFullWithAccentsModuleContainer sidenav={sidenav} login={login} ref={ref} show={inView}>
      <BlockWithDecorators
        show={true}
        leftDecorator={leftDecorator}
        rightDecorator={rightDecorator}
        backgroundColor={backgroundColor}>
        <BlockItem
          theme={blocks[0].theme}
          foregroundColor={blocks[0].foregroundColor}
          captionScale={blocks[0].captionScale}
          heading={blocks[0].header}
          body={blocks[0].body}
          image={blocks[0].image}
          wazer={blocks[0].wazer}
          align={blocks[0].align}
          buttons={blocks[0].buttons}
          animation={blocks[0].animation}
        />
      </BlockWithDecorators>
      <WazerContainer direction={get(wazer, 'direction', 'left')} position={get(wazer, 'position')}>
        <Image width={63} src={get(wazer, 'image')} />
      </WazerContainer>
      <Wazer
        image={get(wazer, 'image')}
        direction={get(wazer, 'direction', 'left')}
        position={get(wazer, 'position')}
        animate={get(wazer, 'animate')}
      />
    </BlockFullWithAccentsModuleContainer>
  );
};

export default BlockFullWithAccentsModule;

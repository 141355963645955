import tw, { styled } from 'twin.macro';
import { getDesignSystemValue } from 'config/design';

interface ContainerProps {
  login: boolean;
  sidenav: boolean;
  backgroundColor?: string;
  show?: boolean;
}

export const HeroModuleContainer = styled.div(
  ({ login, sidenav, backgroundColor = 'gray', show = false }: ContainerProps) => [
    tw`
    flex
    justify-center
    rounded-cardRadius
    w-full
    md:col-auto
    delay-500
    duration-700
    ease-in-out
    opacity-0
    transition-all
    translate-y-4
    transform
  `,
    sidenav && show
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    login &&
      show &&
      tw`row-start-1 row-end-9 col-start-1 col-end-1 md:col-start-1 md:col-end-1 md:row-start-1 md:row-end-1`,
    `background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)}}`,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`
  ]
);

export const HeroModuleContent = styled.div`
  ${tw`
    w-full
  `};
`;

import React, { ReactElement } from 'react';

import {
  MAIN_LOGIN_FORM_TITLE,
  MAIN_LOGIN_FORM_DESCRIPTION_MARKDOWN,
  MAIN_LOGIN_FORM_SUBMIT_LABEL,
  MAIN_LOGIN_FORM_FOOT_MARKDOWN,
  MAIN_LOGIN_FORM_SIGN_IN_WITH_GOOGLE_LABEL
} from 'config';

import Button from 'component/Button/Button';
import BlockItem from 'component/BlockItem/BlockItem';
import MarkdownText from 'component/MarkdownText/MarkdownText';

import { GoogleLogo } from 'illustration';

import { auth } from 'lib/firebase';
import { googleProvider } from 'lib/firebase';

import {
  GoogleSignInButtonContainer,
  ButtonsContainer,
  FootContainer,
  MainLoginFormContainer
} from './element';

interface Props {
  formTitle?: string;
  formDescriptionMarkdown?: string;
  formSubmitLabel?: string;
  formFootMarkdown?: string;
  formSignInWithGoogleLabel?: string;
  targetForm?: string;
  setTargetForm: (formName: string) => void;
}

const Buttons = ({
  formSignInWithGoogleLabel,
  formSubmitLabel,
  setTargetForm,
  triggerSignInWithGoogle
}: {
  formSignInWithGoogleLabel: string,
  formSubmitLabel: string,
  setTargetForm: (e: string) => void,
  triggerSignInWithGoogle: () => Promise<void>
}) => (
  <ButtonsContainer>
    <Button theme="PrimaryWhite" size="Small">
      <GoogleSignInButtonContainer onClick={triggerSignInWithGoogle}>
        <GoogleLogo />
        {formSignInWithGoogleLabel}
      </GoogleSignInButtonContainer>
    </Button>
    <Button theme="PrimaryBlue" size="Small" onClick={() => setTargetForm('LoginWithEmailForm')}>
      {formSubmitLabel}
    </Button>
  </ButtonsContainer>
);

export const MainLoginForm = ({
  formTitle = MAIN_LOGIN_FORM_TITLE,
  formDescriptionMarkdown = MAIN_LOGIN_FORM_DESCRIPTION_MARKDOWN,
  formSubmitLabel = MAIN_LOGIN_FORM_SUBMIT_LABEL,
  formSignInWithGoogleLabel = MAIN_LOGIN_FORM_SIGN_IN_WITH_GOOGLE_LABEL,
  formFootMarkdown = MAIN_LOGIN_FORM_FOOT_MARKDOWN,
  setTargetForm
}: Props): ReactElement => {
  const triggerSignInWithGoogle = async () => {
    await auth.signInWithPopup(googleProvider());
  };

  return (
    <MainLoginFormContainer>
      <BlockItem
        theme="BigHeadingText"
        captionScale="S"
        foregroundColor="black"
        heading={formTitle}
        body={formDescriptionMarkdown}
        bodyClickHandler={() => setTargetForm('SignUpWithEmailForm')}
        withLayout
      />
      <Buttons
        formSignInWithGoogleLabel={formSignInWithGoogleLabel}
        formSubmitLabel={formSubmitLabel}
        setTargetForm={setTargetForm}
        triggerSignInWithGoogle={triggerSignInWithGoogle}
      />
      <FootContainer>
        <MarkdownText
          markdownText={formFootMarkdown}
          onClickHandler={() => setTargetForm('ForgotPasswordEmailForm')}
        />
      </FootContainer>
    </MainLoginFormContainer>
  );
};

export default MainLoginForm;

import React, { ReactNode, ReactElement } from 'react';

import { TwoUpNxNContainer, TwoUpNxNBlock } from './element';

interface TwoUpNxNProps {
  columns?: string;
  children?: ReactNode[];
}

export const TwoUpNxN = ({ columns = '6/6', children = [] }: TwoUpNxNProps): ReactElement => {
  const columnsValue = columns.split('/');
  return (
    <TwoUpNxNContainer>
      {children.map((child: ReactNode, index: number) => (
        <TwoUpNxNBlock key={`two_up_module_block_${index}`} columns={columnsValue[index]}>
          {child}
        </TwoUpNxNBlock>
      ))}
    </TwoUpNxNContainer>
  );
};

export default TwoUpNxN;

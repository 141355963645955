import tw, { styled } from 'twin.macro';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getDesignSystemValue } = require('config/tailwind.config');

interface ResourcesSummaryModuleContainerProps {
  layout?: string;
  orientation?: 'left' | 'right';
  backgroundColor?: string;
  show?: boolean;
}

export const ResourcesSummaryModuleContainer = styled.div<ResourcesSummaryModuleContainerProps>(
  ({ show = false }) => [
    tw`
    grid
    grid-flow-col
    gap-3
    col-start-1
    col-end-3
    md:grid
    md:grid-flow-row
    md:gap-3
    delay-700
    duration-700
    ease-in-out
    opacity-0
    transition-all
    translate-y-4
    transform
    opacity-0
  `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`
  ]
);

export const ResourcesSummaryItemContainer = styled.div`
  ${tw`
    flex
    flex-col
    items-center
    font-boing
    border
    rounded-cardRadius
    bg-gray
    p-9
  `}
  background-color: ${({
    backgroundColor = 'gray'
  }: ResourcesSummaryModuleContainerProps): string =>
    getDesignSystemValue(`colors.${backgroundColor}`)}

`;

export const ResourcesSummaryModuleImageContainer = styled.div`
  ${tw`
    mb-2
  `}
`;

export const ResourcesSummaryModuleMarkdownContainer = styled.div`
  ${tw`
    my-2
    w-4/5
  `}

  * {
    ${tw`
      text-center
      w-full
    `}
  }
`;

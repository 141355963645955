import React, { ReactElement } from 'react';
import { FormikProps, Formik, FormikHelpers } from 'formik';

import {
  LOGIN_FORM_TITLE,
  LOGIN_FORM_DESCRIPTION_MARKDOWN,
  LOGIN_FORM_SUBMIT_LABEL,
  LOGIN_FORM_BACK_LABEL
} from 'config';

import Form from 'form/Form';
import FormState, { FormStateType } from 'container/FormState';
import BlockItem from 'component/BlockItem/BlockItem';

import { auth } from 'lib/firebase';

import { LoginForm } from './LoginForm';
import { LoginWithEmailFormSchema } from './schema';
import { LoginWithEmailFormContainer, StyledLoginWithEmailForm } from './element';

interface Values {
  email: string;
  password: string;
}

interface Props {
  // @ts-ignore
  onSuccess: (user?: firebase.auth.UserCredential) => void;
  formTitle?: string;
  formDescriptionMarkdown?: string;
  formSubmitLabel?: string;
  formFootMarkdown?: string;
  formBackLabel?: string;
  setTargetForm: (fileName: string) => void;
}

const onSubmit = async (
  {
    email,
    password,
    formState
  }: {
    email: string,
    password: string,
    formState: FormStateType
  },
  actions: FormikHelpers<{
    email: string,
    password: string
  }>
) => {
  const { setErrorMessage } = formState;

  try {
    actions.setSubmitting(true);
    // User will be redirected from AuthContext
    await auth.signInWithEmailAndPassword(email, password);
    actions.setSubmitting(false);
  } catch (error) {
    setErrorMessage(error.message);
  }
  actions.resetForm();
};

export const LoginWithEmailForm = ({
  formTitle = LOGIN_FORM_TITLE,
  formDescriptionMarkdown = LOGIN_FORM_DESCRIPTION_MARKDOWN,
  formSubmitLabel = LOGIN_FORM_SUBMIT_LABEL,
  formBackLabel = LOGIN_FORM_BACK_LABEL,
  setTargetForm
}: Props): ReactElement => {
  const formState = FormState.useContainer();

  return (
    <LoginWithEmailFormContainer>
      <BlockItem
        theme="BigHeadingText"
        captionScale="S"
        foregroundColor="black"
        heading={formTitle}
        body={formDescriptionMarkdown}
        withLayout
      />
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginWithEmailFormSchema}
        onSubmit={({ email, password }, actions) => {
          onSubmit({ email, password, formState }, actions);
        }}>
        {({ touched, errors, submitForm }: FormikProps<Values>) => (
          <StyledLoginWithEmailForm>
            <Form errors={errors} touched={touched}>
              <LoginForm
                submitForm={submitForm}
                setTargetForm={setTargetForm}
                formSubmitLabel={formSubmitLabel}
                formBackLabel={formBackLabel}
              />
            </Form>
          </StyledLoginWithEmailForm>
        )}
      </Formik>
    </LoginWithEmailFormContainer>
  );
};

export default LoginWithEmailForm;

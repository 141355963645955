import tw, { styled } from 'twin.macro';

interface ContainerProps {
  sidenav: boolean;
  show?: boolean;
  singleColumn?: boolean;
  disableOnMobile?: boolean;
}

export const Container = styled.div(
  ({ sidenav, show = false, singleColumn = false, disableOnMobile = false }: ContainerProps) => [
    tw`
    w-full
    relative
    md:col-auto
    delay-700
    duration-700
    ease-in-out
    opacity-0
    transition-opacity
    `,
    show && tw`opacity-100`,
    sidenav
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    singleColumn && tw`col-start-1 col-end-1 md:col-start-1 md:col-end-3`,
    disableOnMobile && tw`md:hidden`
  ]
);

import React, { ReactElement } from 'react';
import { get, findKey } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { foregroundConfig } from 'config/design';

import Image from 'component/Image/Image';
import BlockItem from 'component/BlockItem/BlockItem';

import {
  SectionTextImageModuleContainer,
  SectionTextImageModuleTextContainer,
  SectionTextImageModuleImageContainer
} from './element';

export interface Props {
  backgroundColor?: string;
  orientation?: OrientationPropType;
  aspectRatio?: AspectRatioType;
  captionScale?: CaptionScaleNameType;
  heading?: string;
  description?: string;
  image?: ImageType;
  footText?: string;
  button?: Button;
}

export const SectionTextImageModule = ({
  backgroundColor = 'gray',
  orientation,
  aspectRatio,
  captionScale,
  heading,
  description,
  image,
  footText,
  button
}: Props): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <SectionTextImageModuleContainer aspectRatio={aspectRatio} ref={ref} show={inView}>
      <SectionTextImageModuleTextContainer orientation={orientation}>
        <BlockItem
          captionScale={captionScale}
          foregroundColor={findKey(
            foregroundConfig,
            (colors: string[]): boolean => colors.indexOf(backgroundColor) >= 0
          )}
          heading={heading}
          body={description}
          buttons={button ? [button] : undefined}
          footText={footText}
          align="left"
          withoutContainer
        />
      </SectionTextImageModuleTextContainer>
      <SectionTextImageModuleImageContainer orientation={orientation}>
        <Image src={get(image, 'path', '')} size={get(image, 'size', 'Med')} />
      </SectionTextImageModuleImageContainer>
    </SectionTextImageModuleContainer>
  );
};

export default SectionTextImageModule;

import tw, { css, styled } from 'twin.macro';
import { Link } from 'gatsby';

import { getDesignSystemValue } from 'config/design';

interface ContainerProps {
  sidenav: boolean;
  hasNext?: boolean;
  hasPrev?: boolean;
  show?: boolean;
}

interface LinkContainer {
  direction: 'left' | 'right';
}

const translateValue = getDesignSystemValue('extend.margin["1"]');

export const Container = styled.div(
  ({ sidenav, hasNext, hasPrev, show = false }: ContainerProps) => [
    tw`
    flex
    bg-gray
    rounded-cardRadius
    items-center
    justify-between
    px-3
    min-h-20
    w-full
    md:col-auto
    delay-700
    duration-700
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0`,
    sidenav
      ? tw`col-start-2 col-end-3 md:col-start-1 md:col-end-3`
      : tw`col-start-1 col-end-3 md:col-start-1 md:col-end-3`,
    hasNext && !hasPrev
      ? css`
          justify-content: flex-end;
        `
      : hasPrev && !hasNext
      ? css`
          justify-content: flex-start;
        `
      : '',
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`
  ]
);

export const LinkContainer = styled.div``;

export const StyledLink = styled(Link)(({ direction }: LinkContainer) => [
  tw`
    flex
    items-center
    cursor-pointer
    w-auto
  `,
  tw`text-black font-medium no-underline ms-2xl leading-none`,
  direction === 'left' ? tw`mr-2` : tw`ml-2`,
  tw`sm:text-transparent`,
  `
    img {
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    :hover {
      img {
        transform: ${
          direction === 'left' ? `translateX(-${translateValue})` : `translateX(${translateValue})`
        };
      }
    }
  `
]);

import tw, { styled } from 'twin.macro';

import { sectionByAspectRatio } from 'config/design';

interface SectionTextImageModuleContainerProps {
  aspectRatio?: AspectRatioType;
  show?: boolean;
}

interface SectionTextImageModuleColumnProps {
  orientation?: OrientationPropType;
}

export const SectionTextImageModuleContainer = styled.div<SectionTextImageModuleContainerProps>(
  ({ aspectRatio = '1/1', show = false }: SectionTextImageModuleContainerProps) => [
    tw`
      grid
      grid-flow-col
      col-gap-6
      row-gap-8
      rounded-cardRadius
      w-full
      sm:grid-flow-row
      sm:gap-4
      sm:pb-4
      delay-700
      duration-500
      ease-in-out
      opacity-0
      transition-opacity
    `,
    show && tw`opacity-100`,
    sectionByAspectRatio[aspectRatio]
  ]
);

export const SectionTextImageModuleTextContainer = styled.div(
  ({ orientation }: SectionTextImageModuleColumnProps) => [
    tw`
      flex
      justify-start
      sm:px-1
    `,
    orientation === 'left' ? tw`order-1 sm:order-2` : tw`order-2 sm:order-2`
  ]
);

export const SectionTextImageModuleImageContainer = styled.div(
  ({ orientation }: SectionTextImageModuleColumnProps) => [
    tw`
      flex
      justify-center
      items-center
    `,
    orientation === 'left' ? tw`order-2 sm:order-1` : tw`order-1 sm:order-1`
  ]
);

export const SectionTextImageModuleMarkdownContainer = styled.div``;

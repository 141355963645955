import React, { ReactElement } from 'react';
import { get, findKey } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { foregroundConfig } from 'config/design';

import { Image, Heading, Paragraph } from 'component';

import {
  SectionImageAboveModuleContainer,
  SectionImageAboveModuleImagesContainer,
  SectionImageAboveModuleTextContainer
} from './element';

export interface Props {
  backgroundColor?: string;
  heading: string;
  description: string;
  images?: ImageType[];
}

export const SectionImageAboveModule = ({
  backgroundColor = 'gray',
  heading,
  description,
  images
}: Props): ReactElement => {
  const foregroundColor = findKey(
    foregroundConfig,
    (colors: string[]): boolean => colors.indexOf(backgroundColor) >= 0
  );

  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  return (
    <SectionImageAboveModuleContainer ref={ref} show={inView}>
      <SectionImageAboveModuleImagesContainer>
        {images && images.length > 0
          ? images.map((image: ImageType, index: number) => (
              <Image
                key={`section_image_${index}`}
                src={get(image, 'path', '')}
                size={get(image, 'size')}
              />
            ))
          : null}
      </SectionImageAboveModuleImagesContainer>
      <SectionImageAboveModuleTextContainer>
        <Heading captionScaleName="XS" textStyle={{ color: foregroundColor }}>
          {heading}
        </Heading>
        <Paragraph
          captionScaleName="XS"
          textStyle={{ color: foregroundColor, marginBottom: '3' }}
          textStyleForMobile={{ marginBottom: '2' }}
          markdownText={description}>
          {description}
        </Paragraph>
      </SectionImageAboveModuleTextContainer>
    </SectionImageAboveModuleContainer>
  );
};

export default SectionImageAboveModule;
